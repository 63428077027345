import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { useScrollToAnchor } from "@vapaus/common-v2";
import { Button, Flex, Paper, Typography, useBreakpoints } from "@vapaus/ui-v2";

import fullPreCycledLogo from "../../../../../../../assets/full-precycled-logo.svg";

export function PrecycledCard() {
  const { t } = useTranslation();
  const { isXs } = useBreakpoints();
  const precycledRef = useScrollToAnchor("precycled");

  return (
    <Flex ref={precycledRef} direction="column" gap="md">
      <Typography variant="h2">
        {t("userV2:getABike.preCycledCard.preCycled")}
      </Typography>
      <StyledPaper shadow="md" padding="none">
        <Flex xsDirection="column-reverse" gap="none" align="stretch">
          <Flex
            fullWidth
            direction="column"
            padding="xl"
            xsPadding="lg"
            gap="md"
          >
            <Flex direction="column">
              <Flex direction="column" gap="xs">
                <Typography variant="h6">
                  {t("userV2:getABike.preCycledCard.title")}
                </Typography>
                <Typography variant="h4">
                  {t("userV2:getABike.preCycledCard.subtitle")}
                </Typography>
              </Flex>
              <Typography>
                {t("userV2:getABike.preCycledCard.description")}
              </Typography>
            </Flex>
            <Flex>
              <StyledLink
                href={t("userV2:getABike.preCycledCard.link")}
                target="_blank"
                rel="noreferrer"
              >
                <Button fullWidth size={isXs ? "small" : "default"}>
                  {t("userV2:getABike.preCycledCard.browseBikes")}
                </Button>
              </StyledLink>
            </Flex>
          </Flex>
          <StyledPrecycledLogoWrapper
            fullWidth
            align="center"
            justify="center"
            padding="xl"
          >
            <StyledPrecycledLogo src={fullPreCycledLogo} alt="Precycled Logo" />
          </StyledPrecycledLogoWrapper>
        </Flex>
      </StyledPaper>
    </Flex>
  );
}

const StyledPaper = styled(Paper)`
  overflow: hidden;
`;

const StyledPrecycledLogo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 318px;
  max-height: 256px;
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      max-height: 156px;
    `)}
`;

const StyledPrecycledLogoWrapper = styled(Flex)`
  background: ${({ theme }) => theme.palette.secondary1.main};
`;

const StyledLink = styled.a`
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 100%;
    `)}
`;
