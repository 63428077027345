import { useCallback } from "react";

import { snakeCase } from "lodash-es";

import { ShopType } from "@vapaus/api-codegen";
import { useTableParams } from "@vapaus/ui-v2";

import { useSearchShops } from "../../../../../../api/shop";
import { useGetCurrentUser } from "../../../../../../api/user";

export function useShopsTable() {
  const defaultSortId = "name";

  const {
    sorting,
    pagination,
    q: searchQuery,
    city: filteredCity,
    brandId: filteredBrandId,
    shopType: filteredShopType,
    setSorting,
    setPagination,
    setSearchQuery,
    setExtraParams,
  } = useTableParams<{
    city: string;
    brandId: string;
    shopType: string;
    q?: string;
  }>({
    pageSize: 5,
    sortId: defaultSortId,
    sortDesc: false,
    brandId: "",
    shopType: "",
    city: "",
  });
  const { data: user } = useGetCurrentUser();

  const { data } = useSearchShops({
    limit: pagination.pageSize,
    skip: pagination.pageIndex * pagination.pageSize,
    sortField: snakeCase(
      sorting[0]?.id === "null" ? defaultSortId : sorting[0]?.id,
    ),
    sortDirection: sorting[0]?.desc ? "desc" : "asc",
    operationalCountry: user?.country ?? "FI",
    q: searchQuery,
    brandIds: filteredBrandId ? [filteredBrandId] : undefined,
    city: filteredCity ? filteredCity : undefined,
    shopType: filteredShopType ? (filteredShopType as ShopType) : undefined,
  });

  const paginationOptions = data?.total
    ? {
        totalItems: data.total,
        pagination,
        setPagination,
      }
    : undefined;

  const sortingOptions = {
    sorting,
    setSorting,
  };

  const setFilteredCity = useCallback(
    (value: string | null) => {
      setExtraParams({ city: value || "" });
    },
    [setExtraParams],
  );

  const setFilteredBrand = useCallback(
    (value: string | null) => {
      setExtraParams({ brandId: value || "" });
    },
    [setExtraParams],
  );

  const setFilteredShopType = useCallback(
    (value: string | null) => {
      setExtraParams({ shopType: value || "" });
    },
    [setExtraParams],
  );

  const areFiltersEmpty = Boolean(
    !searchQuery && !filteredCity && !filteredBrandId && !filteredShopType,
  );

  const clearFilters = useCallback(() => {
    setExtraParams({ brandId: "", city: "", q: "", shopType: "" });
  }, [setExtraParams]);

  return {
    data: data?.items || [],
    paginationOptions,
    sortingOptions,
    searchQuery,
    filteredCity,
    areFiltersEmpty,
    filteredBrandId,
    filteredShopType,
    setSearchQuery,
    setFilteredCity,
    clearFilters,
    setFilteredBrand,
    setFilteredShopType,
  };
}
