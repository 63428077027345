/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeBenefitContractReturnOrRedemptionReason } from "./BikeBenefitContractReturnOrRedemptionReason";
import {
  BikeBenefitContractReturnOrRedemptionReasonFromJSON,
  BikeBenefitContractReturnOrRedemptionReasonFromJSONTyped,
  BikeBenefitContractReturnOrRedemptionReasonToJSON,
} from "./BikeBenefitContractReturnOrRedemptionReason";
import type { RedemptionProcessStatus } from "./RedemptionProcessStatus";
import {
  RedemptionProcessStatusFromJSON,
  RedemptionProcessStatusFromJSONTyped,
  RedemptionProcessStatusToJSON,
} from "./RedemptionProcessStatus";

/**
 *
 * @export
 * @interface RedemptionProcessRead
 */
export interface RedemptionProcessRead {
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessRead
   */
  bikeBenefitContractId: string;
  /**
   *
   * @type {Date}
   * @memberof RedemptionProcessRead
   */
  plannedRedemptionDate: Date;
  /**
   *
   * @type {BikeBenefitContractReturnOrRedemptionReason}
   * @memberof RedemptionProcessRead
   */
  redemptionReason: BikeBenefitContractReturnOrRedemptionReason;
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessRead
   */
  requestingUserId?: string;
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessRead
   */
  saleInvoiceId?: string;
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessRead
   */
  cancellingUserId?: string;
  /**
   *
   * @type {Date}
   * @memberof RedemptionProcessRead
   */
  cancelledAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof RedemptionProcessRead
   */
  finalisedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessRead
   */
  id: string;
  /**
   *
   * @type {RedemptionProcessStatus}
   * @memberof RedemptionProcessRead
   */
  status: RedemptionProcessStatus;
}

/**
 * Check if a given object implements the RedemptionProcessRead interface.
 */
export function instanceOfRedemptionProcessRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "bikeBenefitContractId" in value;
  isInstance = isInstance && "plannedRedemptionDate" in value;
  isInstance = isInstance && "redemptionReason" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "status" in value;

  return isInstance;
}

export function RedemptionProcessReadFromJSON(
  json: any,
): RedemptionProcessRead {
  return RedemptionProcessReadFromJSONTyped(json, false);
}

export function RedemptionProcessReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RedemptionProcessRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bikeBenefitContractId: json["bike_benefit_contract_id"],
    plannedRedemptionDate: new Date(json["planned_redemption_date"]),
    redemptionReason: BikeBenefitContractReturnOrRedemptionReasonFromJSON(
      json["redemption_reason"],
    ),
    requestingUserId: !exists(json, "requesting_user_id")
      ? undefined
      : json["requesting_user_id"],
    saleInvoiceId: !exists(json, "sale_invoice_id")
      ? undefined
      : json["sale_invoice_id"],
    cancellingUserId: !exists(json, "cancelling_user_id")
      ? undefined
      : json["cancelling_user_id"],
    cancelledAt: !exists(json, "cancelled_at")
      ? undefined
      : new Date(json["cancelled_at"]),
    finalisedAt: !exists(json, "finalised_at")
      ? undefined
      : new Date(json["finalised_at"]),
    id: json["id"],
    status: RedemptionProcessStatusFromJSON(json["status"]),
  };
}

export function RedemptionProcessReadToJSON(
  value?: RedemptionProcessRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bike_benefit_contract_id: value.bikeBenefitContractId,
    planned_redemption_date: value.plannedRedemptionDate
      .toISOString()
      .substring(0, 10),
    redemption_reason: BikeBenefitContractReturnOrRedemptionReasonToJSON(
      value.redemptionReason,
    ),
    requesting_user_id: value.requestingUserId,
    sale_invoice_id: value.saleInvoiceId,
    cancelling_user_id: value.cancellingUserId,
    cancelled_at:
      value.cancelledAt === undefined
        ? undefined
        : value.cancelledAt.toISOString(),
    finalised_at:
      value.finalisedAt === undefined
        ? undefined
        : value.finalisedAt.toISOString(),
    id: value.id,
    status: RedemptionProcessStatusToJSON(value.status),
  };
}
