import { UserShopRead } from "@vapaus/api-codegen";
import { Flex } from "@vapaus/ui-v2";

import { ShopDetailsBodySection } from "./ShopDetailsBodySection";
import { ShopDetailsHeaderSection } from "./ShopDetailsHeaderSection";

type ShopDetailsProps = {
  shop: UserShopRead;
};

export function ShopDetails({ shop }: ShopDetailsProps) {
  return (
    <Flex direction="column" gap="none">
      <ShopDetailsHeaderSection shop={shop} />
      <ShopDetailsBodySection shop={shop} />
    </Flex>
  );
}
