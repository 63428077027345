/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeBenefitContractReturnOrRedemptionReason } from "./BikeBenefitContractReturnOrRedemptionReason";
import {
  BikeBenefitContractReturnOrRedemptionReasonFromJSON,
  BikeBenefitContractReturnOrRedemptionReasonFromJSONTyped,
  BikeBenefitContractReturnOrRedemptionReasonToJSON,
} from "./BikeBenefitContractReturnOrRedemptionReason";
import type { ReturnProcessPickupDetailsWrite } from "./ReturnProcessPickupDetailsWrite";
import {
  ReturnProcessPickupDetailsWriteFromJSON,
  ReturnProcessPickupDetailsWriteFromJSONTyped,
  ReturnProcessPickupDetailsWriteToJSON,
} from "./ReturnProcessPickupDetailsWrite";

/**
 *
 * @export
 * @interface UserReturnProcessCreate
 */
export interface UserReturnProcessCreate {
  /**
   *
   * @type {string}
   * @memberof UserReturnProcessCreate
   */
  bikeBenefitContractId: string;
  /**
   *
   * @type {boolean}
   * @memberof UserReturnProcessCreate
   */
  isEndOfLeaseReturn: boolean;
  /**
   *
   * @type {Date}
   * @memberof UserReturnProcessCreate
   */
  leaseEndDate: Date;
  /**
   *
   * @type {Set<string>}
   * @memberof UserReturnProcessCreate
   */
  redeemedAccessoryIds: Set<string>;
  /**
   *
   * @type {ReturnProcessPickupDetailsWrite}
   * @memberof UserReturnProcessCreate
   */
  pickupDetails?: ReturnProcessPickupDetailsWrite;
  /**
   *
   * @type {BikeBenefitContractReturnOrRedemptionReason}
   * @memberof UserReturnProcessCreate
   */
  returnReason: BikeBenefitContractReturnOrRedemptionReason;
  /**
   *
   * @type {Array<string>}
   * @memberof UserReturnProcessCreate
   */
  pictureIds: Array<string>;
}

/**
 * Check if a given object implements the UserReturnProcessCreate interface.
 */
export function instanceOfUserReturnProcessCreate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "bikeBenefitContractId" in value;
  isInstance = isInstance && "isEndOfLeaseReturn" in value;
  isInstance = isInstance && "leaseEndDate" in value;
  isInstance = isInstance && "redeemedAccessoryIds" in value;
  isInstance = isInstance && "returnReason" in value;
  isInstance = isInstance && "pictureIds" in value;

  return isInstance;
}

export function UserReturnProcessCreateFromJSON(
  json: any,
): UserReturnProcessCreate {
  return UserReturnProcessCreateFromJSONTyped(json, false);
}

export function UserReturnProcessCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserReturnProcessCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bikeBenefitContractId: json["bike_benefit_contract_id"],
    isEndOfLeaseReturn: json["is_end_of_lease_return"],
    leaseEndDate: new Date(json["lease_end_date"]),
    redeemedAccessoryIds: json["redeemed_accessory_ids"],
    pickupDetails: !exists(json, "pickup_details")
      ? undefined
      : ReturnProcessPickupDetailsWriteFromJSON(json["pickup_details"]),
    returnReason: BikeBenefitContractReturnOrRedemptionReasonFromJSON(
      json["return_reason"],
    ),
    pictureIds: json["picture_ids"],
  };
}

export function UserReturnProcessCreateToJSON(
  value?: UserReturnProcessCreate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bike_benefit_contract_id: value.bikeBenefitContractId,
    is_end_of_lease_return: value.isEndOfLeaseReturn,
    lease_end_date: value.leaseEndDate.toISOString().substring(0, 10),
    redeemed_accessory_ids: Array.from(value.redeemedAccessoryIds as Set<any>),
    pickup_details: ReturnProcessPickupDetailsWriteToJSON(value.pickupDetails),
    return_reason: BikeBenefitContractReturnOrRedemptionReasonToJSON(
      value.returnReason,
    ),
    picture_ids: value.pictureIds,
  };
}
