import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Currency } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import { Card, Flex, Paper, Tooltip, Typography } from "@vapaus/ui-v2";

import { ContractSummaryCardProps } from "../ContractSummaryCard";
import { OrderInfoSection } from "./OrderInfoSection";
import { SummarySection } from "./SummarySection";

type ContractSummaryCardSwedenProps = Omit<
  ContractSummaryCardProps,
  "monthlyTaxableValue"
>;

export function ContractSummaryCardSweden({
  benefitDefinition,
  redemptionPrice,
  residualRatio,
  initialMonthlyTaxableValue,
  leasingPeriodMonths,
  monthlyMaintenanceBudget,
  downPaymentAmount,
  bikeOrderPrice,
  leasingCost,
  swedenSuggestedBenefitValues,
  children,
}: ContractSummaryCardSwedenProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(
    benefitDefinition?.currency || Currency.Eur,
  );
  const totalPayment = (initialMonthlyTaxableValue || 0) * leasingPeriodMonths;

  const OrderInfo = () => (
    <Paper background="secondary1.lighter" padding="lg">
      <Flex direction="column" gap="md">
        <OrderInfoSection
          benefitDefinition={benefitDefinition}
          leasingPeriodMonths={leasingPeriodMonths}
          monthlyMaintenanceBudget={monthlyMaintenanceBudget}
          downPaymentAmount={downPaymentAmount}
          bikeOrderPrice={bikeOrderPrice}
          leasingCost={leasingCost}
        />
      </Flex>
    </Paper>
  );

  return (
    <Card title={t("userV2:myBike.contractSummaryCard.title")}>
      <Typography marginBottom="lg">
        <Trans
          i18nKey="userV2:myBike.contractSummaryCard.sweden.description"
          values={{
            salaryDeduction: formatCurrency(
              swedenSuggestedBenefitValues?.suggestedSalaryDeduction,
            ),
            benefitValue: formatCurrency(
              swedenSuggestedBenefitValues?.monthlyBenefitValueAfterTaxDeduction,
            ),
            durationMonths: leasingPeriodMonths,
            residualValue: formatCurrency(redemptionPrice),
          }}
          components={{
            b: <b />,
          }}
        />
      </Typography>
      <Flex direction="column" gap="md">
        <Paper background="secondary1.lighter" padding="lg">
          <Flex direction="column">
            <Flex justify="space-between">
              <Typography weight="bold">
                {t(
                  "userV2:myBike.contractSummaryCard.sweden.grossSalaryDeduction.label",
                )}
              </Typography>
              <Flex gap="xs">
                <Typography weight="bold">
                  {formatCurrency(
                    swedenSuggestedBenefitValues?.suggestedSalaryDeduction,
                  )}
                </Typography>
                <Tooltip>
                  {t(
                    "userV2:myBike.contractSummaryCard.sweden.grossSalaryDeduction.tooltip",
                  )}
                </Tooltip>
              </Flex>
            </Flex>
            <Flex justify="space-between">
              <Typography weight="bold">
                {t(
                  "userV2:myBike.contractSummaryCard.sweden.benefitValue.label",
                )}
              </Typography>
              <Flex gap="xs">
                <Typography weight="bold">
                  {formatCurrency(
                    swedenSuggestedBenefitValues?.monthlyBenefitValueAfterTaxDeduction,
                  )}
                </Typography>
                <Tooltip>
                  {t(
                    "userV2:myBike.contractSummaryCard.sweden.benefitValue.tooltip",
                  )}
                </Tooltip>
              </Flex>
            </Flex>
            <Typography>
              <Trans
                i18nKey="userV2:myBike.contractSummaryCard.sweden.calculatorLink"
                components={{
                  a: (
                    <Link to={"https://vapaus.se/kalkylator"} target="_blank" />
                  ),
                }}
              />
            </Typography>
          </Flex>
        </Paper>
        {children ? <OrderInfo /> : null}
        <SummarySection
          benefitDefinition={benefitDefinition}
          redemptionPrice={redemptionPrice}
          residualRatio={residualRatio}
          initialMonthlyTaxableValue={initialMonthlyTaxableValue}
          leasingPeriodMonths={leasingPeriodMonths}
          totalPayment={totalPayment}
          children={children}
          isSwedishContract={!!swedenSuggestedBenefitValues}
        />
        {!children ? <OrderInfo /> : null}
      </Flex>
    </Card>
  );
}
