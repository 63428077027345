import { TFunction } from "i18next";

import { ShopType } from "@vapaus/api-codegen";

export function mapShopType(type: ShopType, t: TFunction<"translation">) {
  if (type === ShopType.LocalStore) {
    return t("userV2:getABike.partnerShops.serviceTypes.localShop");
  }
  if (type === ShopType.OnlineStore) {
    return t("userV2:getABike.partnerShops.serviceTypes.onlineShop");
  }
  return t("userV2:getABike.partnerShops.serviceTypes.maintenance");
}
