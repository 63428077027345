import { ReactNode, createContext, useContext, useMemo } from "react";

import { useMachine } from "@xstate/react";
import { useOutletContext, useSearchParams } from "react-router-dom";

import {
  type Accessory,
  BikeBenefitContractRead,
  BikeBenefitContractReturnOrRedemptionReason,
  Currency,
  ReturnProcessPreliminaryData,
} from "@vapaus/api-codegen";
import { useCurrentUser } from "@vapaus/shared-api";

import { EMAIL_VERIFICATION_RETURN_PROCESS_KEY } from "../../../../../constants/emailVerification";
import { VISMA_PAY_RETURN_KEY } from "../../../../../constants/vismaPay";
import { getIsContractEndingSoon } from "../../../../../utils/getIsContractEndingSoon";
import { Context } from "../../ReturnProcessWizard/machines/types";
import { returnProcessMachine } from "../machines/ReturnProcessMachine";
import { Typegen0 } from "../machines/ReturnProcessMachine.typegen";
import { PickupAddressDetails } from "../machines/types";
import { ReturnProcessImage } from "../types";

export type ReturnProcessContextProps = {
  onNext: () => void;
  onPrevious: () => void;
  onCompleted: () => void;
  stateMatches: (state: Typegen0["matchesStates"]) => boolean;
  step: number;
  isContractEndingSoon: boolean;
  preliminaryData?: ReturnProcessPreliminaryData;
  currency: Currency;
  accessories: Array<Accessory>;
  returnReason?: BikeBenefitContractReturnOrRedemptionReason;
  returnMethod?: "pickup" | "selfReturn";
  pickupAddressDetails: PickupAddressDetails;
  accessoryIdsSelectedForRedemption: string[];
  images: ReturnProcessImage[];
  setImages: (
    data: (prevImages: ReturnProcessImage[]) => ReturnProcessImage[],
  ) => void;
  setReturnReason: (data: BikeBenefitContractReturnOrRedemptionReason) => void;
  setPickupAddressDetails: (data: PickupAddressDetails) => void;
  setAccessorySelectedForRedemption: (data: string) => void;
  onSelfReturn: () => void;
  onPickup: () => void;
};

export const ReturnProcessContext = createContext<ReturnProcessContextProps>({
  onNext: () => {},
  onPrevious: () => {},
  onCompleted: () => {},
  stateMatches: (state: Typegen0["matchesStates"]) => false,
  step: 0,
  isContractEndingSoon: false,
  currency: Currency.Eur,
  accessories: [],
  pickupAddressDetails: {} as PickupAddressDetails,
  accessoryIdsSelectedForRedemption: [],
  images: [],
  setImages: () => {},
  setReturnReason: () => null,
  setPickupAddressDetails: () => null,
  setAccessorySelectedForRedemption: () => null,
  onSelfReturn: () => {},
  onPickup: () => {},
});

export const useReturnProcessContext = () => useContext(ReturnProcessContext);

type ReturnProcessProviderProps = {
  children: ReactNode;
};

export function ReturnProcessProvider({
  children,
}: ReturnProcessProviderProps) {
  const [searchParams] = useSearchParams();

  function getStartingState(): Context["startingState"] {
    if (searchParams.has(VISMA_PAY_RETURN_KEY)) {
      return "processingPayment";
    }
    if (searchParams.has(EMAIL_VERIFICATION_RETURN_PROCESS_KEY)) {
      return "emailAddress";
    }
    return "default";
  }

  const { contract } = useOutletContext<{
    contract: BikeBenefitContractRead;
  }>();
  const { data: user } = useCurrentUser();
  const isContractEndingSoon = getIsContractEndingSoon(contract.endDate);

  const initialAccessoriesSelectedForRedemption = contract.accessories
    .filter((accessory) => accessory.accessoryType === "helmet")
    .map((helmet) => helmet.id ?? "");

  const initialReturnReason = isContractEndingSoon
    ? BikeBenefitContractReturnOrRedemptionReason.EndOfLease
    : undefined;

  const [state, send] = useMachine(returnProcessMachine, {
    context: {
      step: 0,
      startingState: getStartingState(),
      isContractEndingSoon,
      returnReason: initialReturnReason,
      accessories: contract.accessories,
      currency: contract.currency,
      contractId: contract.id,
      pickupAddressDetails: {
        address: user?.address,
        postalCode: user?.postCode,
        city: user?.city,
        country: user?.country,
        phoneNumber: user?.phoneNumber,
      },
      accessoryIdsSelectedForRedemption:
        initialAccessoriesSelectedForRedemption,
      images: [],
    },
  });

  const stateMatches = state.matches;

  const contextValue = useMemo(
    () => ({
      ...state.context,
      stateMatches,

      onNext: () => send("NEXT"),
      onPrevious: () => send("BACK"),
      onCompleted: () => send("COMPLETED"),
      onSelfReturn: () => send("SELF_RETURN"),
      onPickup: () => send("PICKUP"),

      setReturnReason: (
        returnReason: BikeBenefitContractReturnOrRedemptionReason,
      ) => send("SET_RETURN_REASON", { returnReason }),
      setPickupAddressDetails: (pickupAddressDetails: PickupAddressDetails) =>
        send("SET_PICKUP_ADDRESS_DETAILS", { pickupAddressDetails }),
      setAccessorySelectedForRedemption: (accessoryId: string) =>
        send("SET_ACCESSORY_SELECTED_FOR_REDEMPTION", { accessoryId }),
      setImages: (
        setImagesFromPrev: (
          prevImages: ReturnProcessImage[],
        ) => ReturnProcessImage[],
      ) => send("SET_IMAGES", { setImagesFromPrev }),
    }),
    [send, state.context, stateMatches],
  );

  return (
    <ReturnProcessContext.Provider value={contextValue}>
      {children}
    </ReturnProcessContext.Provider>
  );
}
