/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { AccessoryStatus } from "./AccessoryStatus";
import {
  AccessoryStatusFromJSON,
  AccessoryStatusFromJSONTyped,
  AccessoryStatusToJSON,
} from "./AccessoryStatus";
import type { AccessoryType } from "./AccessoryType";
import {
  AccessoryTypeFromJSON,
  AccessoryTypeFromJSONTyped,
  AccessoryTypeToJSON,
} from "./AccessoryType";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";

/**
 *
 * @export
 * @interface Accessory
 */
export interface Accessory {
  /**
   *
   * @type {AccessoryType}
   * @memberof Accessory
   */
  accessoryType: AccessoryType;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  manufacturer: string;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  size?: string;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  colour?: string;
  /**
   *
   * @type {number}
   * @memberof Accessory
   */
  purchasePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof Accessory
   */
  purchaseCurrency: Currency;
  /**
   *
   * @type {number}
   * @memberof Accessory
   */
  purchaseVatRate: number;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  shopId: string;
  /**
   *
   * @type {AccessoryStatus}
   * @memberof Accessory
   */
  status: AccessoryStatus;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof Accessory
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof Accessory
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the Accessory interface.
 */
export function instanceOfAccessory(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "accessoryType" in value;
  isInstance = isInstance && "manufacturer" in value;
  isInstance = isInstance && "model" in value;
  isInstance = isInstance && "purchasePrice" in value;
  isInstance = isInstance && "purchaseCurrency" in value;
  isInstance = isInstance && "purchaseVatRate" in value;
  isInstance = isInstance && "shopId" in value;
  isInstance = isInstance && "status" in value;

  return isInstance;
}

export function AccessoryFromJSON(json: any): Accessory {
  return AccessoryFromJSONTyped(json, false);
}

export function AccessoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Accessory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessoryType: AccessoryTypeFromJSON(json["accessory_type"]),
    manufacturer: json["manufacturer"],
    model: json["model"],
    size: !exists(json, "size") ? undefined : json["size"],
    colour: !exists(json, "colour") ? undefined : json["colour"],
    purchasePrice: json["purchase_price"],
    purchaseCurrency: CurrencyFromJSON(json["purchase_currency"]),
    purchaseVatRate: json["purchase_vat_rate"],
    shopId: json["shop_id"],
    status: AccessoryStatusFromJSON(json["status"]),
    url: !exists(json, "url") ? undefined : json["url"],
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
  };
}

export function AccessoryToJSON(value?: Accessory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    accessory_type: AccessoryTypeToJSON(value.accessoryType),
    manufacturer: value.manufacturer,
    model: value.model,
    size: value.size,
    colour: value.colour,
    purchase_price: value.purchasePrice,
    purchase_currency: CurrencyToJSON(value.purchaseCurrency),
    purchase_vat_rate: value.purchaseVatRate,
    shop_id: value.shopId,
    status: AccessoryStatusToJSON(value.status),
    url: value.url,
    id: value.id,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
  };
}
