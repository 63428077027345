import { ReactNode } from "react";

import {
  BenefitDefinitionUserRead,
  SwedenSuggestedBenefitValuesCalculation,
} from "@vapaus/api-codegen";

import { ContractSummaryCardDefault } from "./components/ContractSummaryCardDefault";
import { ContractSummaryCardSweden } from "./components/ContractSummaryCardSweden";

export interface ContractSummaryCardProps {
  benefitDefinition?: BenefitDefinitionUserRead;
  redemptionPrice?: number;
  residualRatio?: number;
  initialMonthlyTaxableValue?: number;
  monthlyTaxableValue?: number;
  leasingPeriodMonths: number;
  monthlyMaintenanceBudget: number;
  downPaymentAmount?: number;
  bikeOrderPrice: number;
  leasingCost?: number;
  swedenSuggestedBenefitValues?: SwedenSuggestedBenefitValuesCalculation;
  children?: ReactNode;
}

export function ContractSummaryCard({
  benefitDefinition,
  redemptionPrice,
  residualRatio,
  initialMonthlyTaxableValue,
  monthlyTaxableValue,
  leasingPeriodMonths,
  monthlyMaintenanceBudget,
  downPaymentAmount,
  bikeOrderPrice,
  leasingCost,
  swedenSuggestedBenefitValues,
  children,
}: ContractSummaryCardProps) {
  return swedenSuggestedBenefitValues ? (
    <ContractSummaryCardSweden
      benefitDefinition={benefitDefinition}
      redemptionPrice={redemptionPrice}
      residualRatio={residualRatio}
      initialMonthlyTaxableValue={initialMonthlyTaxableValue}
      leasingPeriodMonths={leasingPeriodMonths}
      monthlyMaintenanceBudget={monthlyMaintenanceBudget}
      downPaymentAmount={downPaymentAmount}
      bikeOrderPrice={bikeOrderPrice}
      leasingCost={leasingCost}
      swedenSuggestedBenefitValues={swedenSuggestedBenefitValues}
      children={children}
    />
  ) : (
    <ContractSummaryCardDefault
      benefitDefinition={benefitDefinition}
      redemptionPrice={redemptionPrice}
      residualRatio={residualRatio}
      initialMonthlyTaxableValue={initialMonthlyTaxableValue}
      monthlyTaxableValue={monthlyTaxableValue}
      leasingPeriodMonths={leasingPeriodMonths}
      monthlyMaintenanceBudget={monthlyMaintenanceBudget}
      downPaymentAmount={downPaymentAmount}
      bikeOrderPrice={bikeOrderPrice}
      leasingCost={leasingCost}
      children={children}
    />
  );
}
