/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BenefitDefinitionCreate,
  BenefitDefinitionRead,
  BenefitDefinitionReadWithOrganisationSummary,
  BenefitDefinitionTermsRead,
  BenefitDefinitionUpdate,
  BenefitDefinitionUserRead,
  BodyBenefitDefinitionsDuplicateBenefitDefinition,
  BodyBenefitDefinitionsProcessBenefitDefinitionMerger,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedBenefitDefinitionCandidateOut,
  PaginatedBenefitDefinitionTermsOut,
  PaginatedUserBenefitActivationOut,
  SupportedOrganisationCountries,
  UserBenefitActivationRead,
} from "../models/index";
import {
  BenefitDefinitionCreateFromJSON,
  BenefitDefinitionCreateToJSON,
  BenefitDefinitionReadFromJSON,
  BenefitDefinitionReadToJSON,
  BenefitDefinitionReadWithOrganisationSummaryFromJSON,
  BenefitDefinitionReadWithOrganisationSummaryToJSON,
  BenefitDefinitionTermsReadFromJSON,
  BenefitDefinitionTermsReadToJSON,
  BenefitDefinitionUpdateFromJSON,
  BenefitDefinitionUpdateToJSON,
  BenefitDefinitionUserReadFromJSON,
  BenefitDefinitionUserReadToJSON,
  BodyBenefitDefinitionsDuplicateBenefitDefinitionFromJSON,
  BodyBenefitDefinitionsDuplicateBenefitDefinitionToJSON,
  BodyBenefitDefinitionsProcessBenefitDefinitionMergerFromJSON,
  BodyBenefitDefinitionsProcessBenefitDefinitionMergerToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedBenefitDefinitionCandidateOutFromJSON,
  PaginatedBenefitDefinitionCandidateOutToJSON,
  PaginatedBenefitDefinitionTermsOutFromJSON,
  PaginatedBenefitDefinitionTermsOutToJSON,
  PaginatedUserBenefitActivationOutFromJSON,
  PaginatedUserBenefitActivationOutToJSON,
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesToJSON,
  UserBenefitActivationReadFromJSON,
  UserBenefitActivationReadToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface BenefitDefinitionsActivateBenefitDefinitionRequest {
  benefitId: string;
}

export interface BenefitDefinitionsActivateBenefitDefinitionTermsRequest {
  benefitDefinitionTermsId: string;
}

export interface BenefitDefinitionsCreateBenefitDefinitionRequest {
  benefitDefinitionCreate: BenefitDefinitionCreate;
}

export interface BenefitDefinitionsCreateBenefitDefinitionTermsRequest {
  benefitDefinitionId: string;
  content?: string;
  pdfObject?: Blob;
}

export interface BenefitDefinitionsDuplicateBenefitDefinitionRequest {
  benefitId: string;
  bodyBenefitDefinitionsDuplicateBenefitDefinition: BodyBenefitDefinitionsDuplicateBenefitDefinition;
}

export interface BenefitDefinitionsGetBenefitDefinitionRequest {
  benefitId: string;
  onlyActive?: boolean;
}

export interface BenefitDefinitionsGetBenefitDefinitionCurrentTermsRequest {
  benefitId: string;
}

export interface BenefitDefinitionsGetBenefitDefinitionTermsRequest {
  benefitDefinitionTermsId: string;
}

export interface BenefitDefinitionsGetBenefitDefinition0Request {
  benefitId: string;
}

export interface BenefitDefinitionsGetBenefitDefinitionsActivationsRequest {
  activeOnly?: boolean;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface BenefitDefinitionsProcessBenefitDefinitionMergerRequest {
  benefitId: string;
  bodyBenefitDefinitionsProcessBenefitDefinitionMerger: BodyBenefitDefinitionsProcessBenefitDefinitionMerger;
}

export interface BenefitDefinitionsSearchBenefitDefinitionRequest {
  organisationId?: string;
  benefitAccessRulesUuid?: string;
  availableForUserId?: string;
  onlyActive?: boolean;
  country?: SupportedOrganisationCountries;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface BenefitDefinitionsSearchBenefitDefinitionTermsRequest {
  benefitDefinitionId?: string;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface BenefitDefinitionsUpdateBenefitDefinitionRequest {
  benefitId: string;
  benefitDefinitionUpdate: BenefitDefinitionUpdate;
}

export interface BenefitDefinitionsUpdateBenefitDefinitionTermsRequest {
  benefitDefinitionTermsId: string;
  content?: string;
  pdfObject?: Blob;
}

/**
 *
 */
export class BenefitDefinitionsApi extends runtime.BaseAPI {
  /**
   * Activate Benefit Definition
   */
  async benefitDefinitionsActivateBenefitDefinitionRaw(
    requestParameters: BenefitDefinitionsActivateBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserBenefitActivationRead>> {
    if (
      requestParameters.benefitId === null ||
      requestParameters.benefitId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitId",
        "Required parameter requestParameters.benefitId was null or undefined when calling benefitDefinitionsActivateBenefitDefinition.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/benefit-definitions/{benefit_id}/activate`.replace(
          `{${"benefit_id"}}`,
          encodeURIComponent(String(requestParameters.benefitId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserBenefitActivationReadFromJSON(jsonValue),
    );
  }

  /**
   * Activate Benefit Definition
   */
  async benefitDefinitionsActivateBenefitDefinition(
    requestParameters: BenefitDefinitionsActivateBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserBenefitActivationRead> {
    const response = await this.benefitDefinitionsActivateBenefitDefinitionRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Activate Benefit Definition Terms
   */
  async benefitDefinitionsActivateBenefitDefinitionTermsRaw(
    requestParameters: BenefitDefinitionsActivateBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionTermsRead>> {
    if (
      requestParameters.benefitDefinitionTermsId === null ||
      requestParameters.benefitDefinitionTermsId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionTermsId",
        "Required parameter requestParameters.benefitDefinitionTermsId was null or undefined when calling benefitDefinitionsActivateBenefitDefinitionTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/benefit-definition-terms/{benefit_definition_terms_id}/activate`.replace(
          `{${"benefit_definition_terms_id"}}`,
          encodeURIComponent(
            String(requestParameters.benefitDefinitionTermsId),
          ),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionTermsReadFromJSON(jsonValue),
    );
  }

  /**
   * Activate Benefit Definition Terms
   */
  async benefitDefinitionsActivateBenefitDefinitionTerms(
    requestParameters: BenefitDefinitionsActivateBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionTermsRead> {
    const response =
      await this.benefitDefinitionsActivateBenefitDefinitionTermsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Create Benefit Definition
   */
  async benefitDefinitionsCreateBenefitDefinitionRaw(
    requestParameters: BenefitDefinitionsCreateBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionRead>> {
    if (
      requestParameters.benefitDefinitionCreate === null ||
      requestParameters.benefitDefinitionCreate === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionCreate",
        "Required parameter requestParameters.benefitDefinitionCreate was null or undefined when calling benefitDefinitionsCreateBenefitDefinition.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/benefit-definitions`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BenefitDefinitionCreateToJSON(
          requestParameters.benefitDefinitionCreate,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionReadFromJSON(jsonValue),
    );
  }

  /**
   * Create Benefit Definition
   */
  async benefitDefinitionsCreateBenefitDefinition(
    requestParameters: BenefitDefinitionsCreateBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionRead> {
    const response = await this.benefitDefinitionsCreateBenefitDefinitionRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Create Benefit Definition Terms
   */
  async benefitDefinitionsCreateBenefitDefinitionTermsRaw(
    requestParameters: BenefitDefinitionsCreateBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionTermsRead>> {
    if (
      requestParameters.benefitDefinitionId === null ||
      requestParameters.benefitDefinitionId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionId",
        "Required parameter requestParameters.benefitDefinitionId was null or undefined when calling benefitDefinitionsCreateBenefitDefinitionTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.benefitDefinitionId !== undefined) {
      formParams.append(
        "benefit_definition_id",
        requestParameters.benefitDefinitionId as any,
      );
    }

    if (requestParameters.content !== undefined) {
      formParams.append("content", requestParameters.content as any);
    }

    if (requestParameters.pdfObject !== undefined) {
      formParams.append("pdf_object", requestParameters.pdfObject as any);
    }

    const response = await this.request(
      {
        path: `/admin-api/benefit-definition-terms`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionTermsReadFromJSON(jsonValue),
    );
  }

  /**
   * Create Benefit Definition Terms
   */
  async benefitDefinitionsCreateBenefitDefinitionTerms(
    requestParameters: BenefitDefinitionsCreateBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionTermsRead> {
    const response =
      await this.benefitDefinitionsCreateBenefitDefinitionTermsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Duplicate Benefit Definition
   */
  async benefitDefinitionsDuplicateBenefitDefinitionRaw(
    requestParameters: BenefitDefinitionsDuplicateBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionRead>> {
    if (
      requestParameters.benefitId === null ||
      requestParameters.benefitId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitId",
        "Required parameter requestParameters.benefitId was null or undefined when calling benefitDefinitionsDuplicateBenefitDefinition.",
      );
    }

    if (
      requestParameters.bodyBenefitDefinitionsDuplicateBenefitDefinition ===
        null ||
      requestParameters.bodyBenefitDefinitionsDuplicateBenefitDefinition ===
        undefined
    ) {
      throw new runtime.RequiredError(
        "bodyBenefitDefinitionsDuplicateBenefitDefinition",
        "Required parameter requestParameters.bodyBenefitDefinitionsDuplicateBenefitDefinition was null or undefined when calling benefitDefinitionsDuplicateBenefitDefinition.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/benefit-definitions/{benefit_id}/duplicate`.replace(
          `{${"benefit_id"}}`,
          encodeURIComponent(String(requestParameters.benefitId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyBenefitDefinitionsDuplicateBenefitDefinitionToJSON(
          requestParameters.bodyBenefitDefinitionsDuplicateBenefitDefinition,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionReadFromJSON(jsonValue),
    );
  }

  /**
   * Duplicate Benefit Definition
   */
  async benefitDefinitionsDuplicateBenefitDefinition(
    requestParameters: BenefitDefinitionsDuplicateBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionRead> {
    const response = await this.benefitDefinitionsDuplicateBenefitDefinitionRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Benefit Definition
   */
  async benefitDefinitionsGetBenefitDefinitionRaw(
    requestParameters: BenefitDefinitionsGetBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionUserRead>> {
    if (
      requestParameters.benefitId === null ||
      requestParameters.benefitId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitId",
        "Required parameter requestParameters.benefitId was null or undefined when calling benefitDefinitionsGetBenefitDefinition.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.onlyActive !== undefined) {
      queryParameters["only_active"] = requestParameters.onlyActive;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/benefit-definitions/{benefit_id}`.replace(
          `{${"benefit_id"}}`,
          encodeURIComponent(String(requestParameters.benefitId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionUserReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Benefit Definition
   */
  async benefitDefinitionsGetBenefitDefinition(
    requestParameters: BenefitDefinitionsGetBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionUserRead> {
    const response = await this.benefitDefinitionsGetBenefitDefinitionRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Benefit Definition Current Terms
   */
  async benefitDefinitionsGetBenefitDefinitionCurrentTermsRaw(
    requestParameters: BenefitDefinitionsGetBenefitDefinitionCurrentTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionTermsRead>> {
    if (
      requestParameters.benefitId === null ||
      requestParameters.benefitId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitId",
        "Required parameter requestParameters.benefitId was null or undefined when calling benefitDefinitionsGetBenefitDefinitionCurrentTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/benefit-definitions/{benefit_id}/current-terms`.replace(
          `{${"benefit_id"}}`,
          encodeURIComponent(String(requestParameters.benefitId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionTermsReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Benefit Definition Current Terms
   */
  async benefitDefinitionsGetBenefitDefinitionCurrentTerms(
    requestParameters: BenefitDefinitionsGetBenefitDefinitionCurrentTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionTermsRead> {
    const response =
      await this.benefitDefinitionsGetBenefitDefinitionCurrentTermsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Get Benefit Definition Terms
   */
  async benefitDefinitionsGetBenefitDefinitionTermsRaw(
    requestParameters: BenefitDefinitionsGetBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionTermsRead>> {
    if (
      requestParameters.benefitDefinitionTermsId === null ||
      requestParameters.benefitDefinitionTermsId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionTermsId",
        "Required parameter requestParameters.benefitDefinitionTermsId was null or undefined when calling benefitDefinitionsGetBenefitDefinitionTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/benefit-definition-terms/{benefit_definition_terms_id}`.replace(
          `{${"benefit_definition_terms_id"}}`,
          encodeURIComponent(
            String(requestParameters.benefitDefinitionTermsId),
          ),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionTermsReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Benefit Definition Terms
   */
  async benefitDefinitionsGetBenefitDefinitionTerms(
    requestParameters: BenefitDefinitionsGetBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionTermsRead> {
    const response = await this.benefitDefinitionsGetBenefitDefinitionTermsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Benefit Definition
   */
  async benefitDefinitionsGetBenefitDefinition_1Raw(
    requestParameters: BenefitDefinitionsGetBenefitDefinition0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<BenefitDefinitionReadWithOrganisationSummary>
  > {
    if (
      requestParameters.benefitId === null ||
      requestParameters.benefitId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitId",
        "Required parameter requestParameters.benefitId was null or undefined when calling benefitDefinitionsGetBenefitDefinition_1.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/benefit-definitions/{benefit_id}`.replace(
          `{${"benefit_id"}}`,
          encodeURIComponent(String(requestParameters.benefitId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionReadWithOrganisationSummaryFromJSON(jsonValue),
    );
  }

  /**
   * Get Benefit Definition
   */
  async benefitDefinitionsGetBenefitDefinition_1(
    requestParameters: BenefitDefinitionsGetBenefitDefinition0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionReadWithOrganisationSummary> {
    const response = await this.benefitDefinitionsGetBenefitDefinition_1Raw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Benefit Definitions Activations
   */
  async benefitDefinitionsGetBenefitDefinitionsActivationsRaw(
    requestParameters: BenefitDefinitionsGetBenefitDefinitionsActivationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedUserBenefitActivationOut>> {
    const queryParameters: any = {};

    if (requestParameters.activeOnly !== undefined) {
      queryParameters["active_only"] = requestParameters.activeOnly;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/benefit-definitions/activations`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedUserBenefitActivationOutFromJSON(jsonValue),
    );
  }

  /**
   * Get Benefit Definitions Activations
   */
  async benefitDefinitionsGetBenefitDefinitionsActivations(
    requestParameters: BenefitDefinitionsGetBenefitDefinitionsActivationsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedUserBenefitActivationOut> {
    const response =
      await this.benefitDefinitionsGetBenefitDefinitionsActivationsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Get Benefit Definitions Candidates
   */
  async benefitDefinitionsGetBenefitDefinitionsCandidatesRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBenefitDefinitionCandidateOut>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/benefit-definitions/candidates`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBenefitDefinitionCandidateOutFromJSON(jsonValue),
    );
  }

  /**
   * Get Benefit Definitions Candidates
   */
  async benefitDefinitionsGetBenefitDefinitionsCandidates(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBenefitDefinitionCandidateOut> {
    const response =
      await this.benefitDefinitionsGetBenefitDefinitionsCandidatesRaw(
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Process Benefit Definition Merger
   */
  async benefitDefinitionsProcessBenefitDefinitionMergerRaw(
    requestParameters: BenefitDefinitionsProcessBenefitDefinitionMergerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionRead>> {
    if (
      requestParameters.benefitId === null ||
      requestParameters.benefitId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitId",
        "Required parameter requestParameters.benefitId was null or undefined when calling benefitDefinitionsProcessBenefitDefinitionMerger.",
      );
    }

    if (
      requestParameters.bodyBenefitDefinitionsProcessBenefitDefinitionMerger ===
        null ||
      requestParameters.bodyBenefitDefinitionsProcessBenefitDefinitionMerger ===
        undefined
    ) {
      throw new runtime.RequiredError(
        "bodyBenefitDefinitionsProcessBenefitDefinitionMerger",
        "Required parameter requestParameters.bodyBenefitDefinitionsProcessBenefitDefinitionMerger was null or undefined when calling benefitDefinitionsProcessBenefitDefinitionMerger.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/benefit-definitions/{benefit_id}/process-merger`.replace(
          `{${"benefit_id"}}`,
          encodeURIComponent(String(requestParameters.benefitId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyBenefitDefinitionsProcessBenefitDefinitionMergerToJSON(
          requestParameters.bodyBenefitDefinitionsProcessBenefitDefinitionMerger,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionReadFromJSON(jsonValue),
    );
  }

  /**
   * Process Benefit Definition Merger
   */
  async benefitDefinitionsProcessBenefitDefinitionMerger(
    requestParameters: BenefitDefinitionsProcessBenefitDefinitionMergerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionRead> {
    const response =
      await this.benefitDefinitionsProcessBenefitDefinitionMergerRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Search Benefit Definition
   */
  async benefitDefinitionsSearchBenefitDefinitionRaw(
    requestParameters: BenefitDefinitionsSearchBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    if (requestParameters.organisationId !== undefined) {
      queryParameters["organisation_id"] = requestParameters.organisationId;
    }

    if (requestParameters.benefitAccessRulesUuid !== undefined) {
      queryParameters["benefit_access_rules_uuid"] =
        requestParameters.benefitAccessRulesUuid;
    }

    if (requestParameters.availableForUserId !== undefined) {
      queryParameters["available_for_user_id"] =
        requestParameters.availableForUserId;
    }

    if (requestParameters.onlyActive !== undefined) {
      queryParameters["only_active"] = requestParameters.onlyActive;
    }

    if (requestParameters.country !== undefined) {
      queryParameters["country"] = requestParameters.country;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/benefit-definitions`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Search Benefit Definition
   */
  async benefitDefinitionsSearchBenefitDefinition(
    requestParameters: BenefitDefinitionsSearchBenefitDefinitionRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.benefitDefinitionsSearchBenefitDefinitionRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Search Benefit Definition Terms
   */
  async benefitDefinitionsSearchBenefitDefinitionTermsRaw(
    requestParameters: BenefitDefinitionsSearchBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBenefitDefinitionTermsOut>> {
    const queryParameters: any = {};

    if (requestParameters.benefitDefinitionId !== undefined) {
      queryParameters["benefit_definition_id"] =
        requestParameters.benefitDefinitionId;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/benefit-definition-terms`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBenefitDefinitionTermsOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Benefit Definition Terms
   */
  async benefitDefinitionsSearchBenefitDefinitionTerms(
    requestParameters: BenefitDefinitionsSearchBenefitDefinitionTermsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBenefitDefinitionTermsOut> {
    const response =
      await this.benefitDefinitionsSearchBenefitDefinitionTermsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Update Benefit Definition
   */
  async benefitDefinitionsUpdateBenefitDefinitionRaw(
    requestParameters: BenefitDefinitionsUpdateBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionRead>> {
    if (
      requestParameters.benefitId === null ||
      requestParameters.benefitId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitId",
        "Required parameter requestParameters.benefitId was null or undefined when calling benefitDefinitionsUpdateBenefitDefinition.",
      );
    }

    if (
      requestParameters.benefitDefinitionUpdate === null ||
      requestParameters.benefitDefinitionUpdate === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionUpdate",
        "Required parameter requestParameters.benefitDefinitionUpdate was null or undefined when calling benefitDefinitionsUpdateBenefitDefinition.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/benefit-definitions/{benefit_id}`.replace(
          `{${"benefit_id"}}`,
          encodeURIComponent(String(requestParameters.benefitId)),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: BenefitDefinitionUpdateToJSON(
          requestParameters.benefitDefinitionUpdate,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionReadFromJSON(jsonValue),
    );
  }

  /**
   * Update Benefit Definition
   */
  async benefitDefinitionsUpdateBenefitDefinition(
    requestParameters: BenefitDefinitionsUpdateBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionRead> {
    const response = await this.benefitDefinitionsUpdateBenefitDefinitionRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Update Benefit Definition Terms
   */
  async benefitDefinitionsUpdateBenefitDefinitionTermsRaw(
    requestParameters: BenefitDefinitionsUpdateBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionTermsRead>> {
    if (
      requestParameters.benefitDefinitionTermsId === null ||
      requestParameters.benefitDefinitionTermsId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionTermsId",
        "Required parameter requestParameters.benefitDefinitionTermsId was null or undefined when calling benefitDefinitionsUpdateBenefitDefinitionTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.content !== undefined) {
      formParams.append("content", requestParameters.content as any);
    }

    if (requestParameters.pdfObject !== undefined) {
      formParams.append("pdf_object", requestParameters.pdfObject as any);
    }

    const response = await this.request(
      {
        path: `/admin-api/benefit-definition-terms/{benefit_definition_terms_id}`.replace(
          `{${"benefit_definition_terms_id"}}`,
          encodeURIComponent(
            String(requestParameters.benefitDefinitionTermsId),
          ),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionTermsReadFromJSON(jsonValue),
    );
  }

  /**
   * Update Benefit Definition Terms
   */
  async benefitDefinitionsUpdateBenefitDefinitionTerms(
    requestParameters: BenefitDefinitionsUpdateBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionTermsRead> {
    const response =
      await this.benefitDefinitionsUpdateBenefitDefinitionTermsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
