/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeBenefitContractReturnOrRedemptionReason } from "./BikeBenefitContractReturnOrRedemptionReason";
import {
  BikeBenefitContractReturnOrRedemptionReasonFromJSON,
  BikeBenefitContractReturnOrRedemptionReasonFromJSONTyped,
  BikeBenefitContractReturnOrRedemptionReasonToJSON,
} from "./BikeBenefitContractReturnOrRedemptionReason";
import type { ReturnProcessPickupDetailsWrite } from "./ReturnProcessPickupDetailsWrite";
import {
  ReturnProcessPickupDetailsWriteFromJSON,
  ReturnProcessPickupDetailsWriteFromJSONTyped,
  ReturnProcessPickupDetailsWriteToJSON,
} from "./ReturnProcessPickupDetailsWrite";

/**
 *
 * @export
 * @interface ReturnProcessPlanInvoicingIn
 */
export interface ReturnProcessPlanInvoicingIn {
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  bikeBenefitContractId: string;
  /**
   *
   * @type {boolean}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  isEndOfLeaseReturn: boolean;
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  leaseEndDate: Date;
  /**
   *
   * @type {Array<string>}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  redeemedAccessoryIds: Array<string>;
  /**
   *
   * @type {ReturnProcessPickupDetailsWrite}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  pickupDetails?: ReturnProcessPickupDetailsWrite;
  /**
   *
   * @type {BikeBenefitContractReturnOrRedemptionReason}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  returnReason: BikeBenefitContractReturnOrRedemptionReason;
}

/**
 * Check if a given object implements the ReturnProcessPlanInvoicingIn interface.
 */
export function instanceOfReturnProcessPlanInvoicingIn(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "bikeBenefitContractId" in value;
  isInstance = isInstance && "isEndOfLeaseReturn" in value;
  isInstance = isInstance && "leaseEndDate" in value;
  isInstance = isInstance && "redeemedAccessoryIds" in value;
  isInstance = isInstance && "returnReason" in value;

  return isInstance;
}

export function ReturnProcessPlanInvoicingInFromJSON(
  json: any,
): ReturnProcessPlanInvoicingIn {
  return ReturnProcessPlanInvoicingInFromJSONTyped(json, false);
}

export function ReturnProcessPlanInvoicingInFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessPlanInvoicingIn {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bikeBenefitContractId: json["bike_benefit_contract_id"],
    isEndOfLeaseReturn: json["is_end_of_lease_return"],
    leaseEndDate: new Date(json["lease_end_date"]),
    redeemedAccessoryIds: json["redeemed_accessory_ids"],
    pickupDetails: !exists(json, "pickup_details")
      ? undefined
      : ReturnProcessPickupDetailsWriteFromJSON(json["pickup_details"]),
    returnReason: BikeBenefitContractReturnOrRedemptionReasonFromJSON(
      json["return_reason"],
    ),
  };
}

export function ReturnProcessPlanInvoicingInToJSON(
  value?: ReturnProcessPlanInvoicingIn | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bike_benefit_contract_id: value.bikeBenefitContractId,
    is_end_of_lease_return: value.isEndOfLeaseReturn,
    lease_end_date: value.leaseEndDate.toISOString().substring(0, 10),
    redeemed_accessory_ids: value.redeemedAccessoryIds,
    pickup_details: ReturnProcessPickupDetailsWriteToJSON(value.pickupDetails),
    return_reason: BikeBenefitContractReturnOrRedemptionReasonToJSON(
      value.returnReason,
    ),
  };
}
