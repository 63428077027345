/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { UserAdminRead } from "./UserAdminRead";
import {
  UserAdminReadFromJSON,
  UserAdminReadFromJSONTyped,
  UserAdminReadToJSON,
} from "./UserAdminRead";

/**
 *
 * @export
 * @interface PaginatedUserAdminOut
 */
export interface PaginatedUserAdminOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedUserAdminOut
   */
  total: number;
  /**
   *
   * @type {Array<UserAdminRead>}
   * @memberof PaginatedUserAdminOut
   */
  items: Array<UserAdminRead>;
}

/**
 * Check if a given object implements the PaginatedUserAdminOut interface.
 */
export function instanceOfPaginatedUserAdminOut(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "items" in value;

  return isInstance;
}

export function PaginatedUserAdminOutFromJSON(
  json: any,
): PaginatedUserAdminOut {
  return PaginatedUserAdminOutFromJSONTyped(json, false);
}

export function PaginatedUserAdminOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedUserAdminOut {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(UserAdminReadFromJSON),
  };
}

export function PaginatedUserAdminOutToJSON(
  value?: PaginatedUserAdminOut | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(UserAdminReadToJSON),
  };
}
