import { ReactNode } from "react";

import styled from "styled-components";

import { Align, Flex, Justify } from "../Flex";
import { Typography } from "../Typography";
import { SpacingVariants, TypographyVariants } from "../styles";

type OrderRowItemProps = {
  label?: string;
  value?: ReactNode;
  labelIsBold?: boolean;
  valueIsBold?: boolean;
  justify?: Justify;
  align?: Align;
  typoVariant?: TypographyVariants;
  gap?: SpacingVariants;
};

export function RowItem({
  label,
  value,
  typoVariant,
  labelIsBold = true,
  valueIsBold = false,
  justify = "space-between",
  align = "center",
  gap = "xs",
}: OrderRowItemProps) {
  return (
    <Flex align={align} justify={justify} gap={gap}>
      <Typography
        weight={labelIsBold ? "bold" : "none"}
        align="left"
        variant={typoVariant}
        textTransform="capitalize"
      >
        {label ?? "-"}
      </Typography>
      {typeof value === "string" ? (
        <Typography
          align="right"
          weight={valueIsBold ? "bold" : "none"}
          variant={typoVariant}
          textTransform="capitalize"
        >
          {value}
        </Typography>
      ) : (
        <StyledSpan>{value}</StyledSpan>
      )}
    </Flex>
  );
}

const StyledSpan = styled.span`
  text-align: right;
`;
