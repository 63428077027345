import { useTranslation } from "react-i18next";

import { UserShopRead } from "@vapaus/api-codegen";
import { Flex, Typography } from "@vapaus/ui-v2";

type BrandsSectionProps = {
  shop: UserShopRead;
};

export function BrandsSection({ shop }: BrandsSectionProps) {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap="xxs">
      <Typography weight="bold">
        {t("userV2:getABike.partnerShops.brands")}
      </Typography>
      <Typography>
        {shop.bikeBrands.map((brand) => brand.name).join(", ")}
      </Typography>
    </Flex>
  );
}
