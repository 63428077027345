import { useCallback, useEffect, useState } from "react";

import { get } from "lodash-es";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";

import {
  BenefitDefinitionUserRead,
  BikeBenefitContractRead,
  ReturnProcessRead,
  SupportedOrganisationCountries,
} from "@vapaus/api-codegen";
import { BenefitTermsModal } from "@vapaus/common-v2";
import {
  ArrowBackIcon,
  CalendarIcon,
  CarriageReturnIcon,
  CircularButton,
  CircularButtonProps,
  Flex,
  HeartIcon,
  MoreIcon,
  OrderApproveIcon,
  PaletteVariants,
  PedalBikeIcon,
  SavingsIcon,
  Select,
  Theme,
} from "@vapaus/ui-v2";

import { Path } from "../../../../constants/paths";
import { getBookReturnDateLink } from "../../../../utils/getBookReturnDateLink";
import {
  UnusedMaintenanceBudgetModal,
  useRedeemReturnCTA,
} from "../RedeemReturnSharedComponents/RedeemReturnCTAButtons";

type HeroNavigationProps = {
  contract?: BikeBenefitContractRead;
  benefitDefinition?: BenefitDefinitionUserRead;
  background: PaletteVariants;
  returnProcess?: ReturnProcessRead;
  handleOpenReturnChecklistModal: () => void;
};

export function HeroNavigation({
  contract,
  benefitDefinition,
  background,
  returnProcess,
  handleOpenReturnChecklistModal,
}: HeroNavigationProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const {
    handleClickRedeemBikeButton,
    handleClickReturnBikeButton,
    handleCloseModalForRedeem,
    handleCloseModalForReturn,
    isOpenForRedeem,
    isOpenForReturn,
    availableMaintenanceBudget,
    hasActiveCancellationCoverage,
    isContractEndingSoon,
  } = useRedeemReturnCTA(contract);

  const bookReturnFormLink = getBookReturnDateLink(
    benefitDefinition?.organisation?.country ??
      SupportedOrganisationCountries.Fi,
  );

  const menuItems = [
    {
      icon: <SavingsIcon />,
      value: "calculate-savings",
      label: t("userV2:myBike.hero.navigationMenuItems.calculateSavings"),
    },
    ...(contract?.state === "active"
      ? [
          {
            icon: <HeartIcon />,
            value: "redeem-bike",
            label: t("userV2:myBike.hero.navigationMenuItems.redeemBike"),
          },
          ...(hasActiveCancellationCoverage || isContractEndingSoon
            ? [
                {
                  icon: <CarriageReturnIcon />,
                  value: "return-bike",
                  label: t("userV2:myBike.hero.navigationMenuItems.returnBike"),
                },
              ]
            : []),
        ]
      : []),
    {
      icon: <OrderApproveIcon />,
      value: "terms-conditions",
      label: t("userV2:myBike.hero.navigationMenuItems.termsAndConditions"),
    },
    ...(contract?.state === "active_pending_return"
      ? [
          ...(!returnProcess?.pickupDetails
            ? [
                {
                  icon: <CalendarIcon />,
                  value: "book-return-date",
                  label: t(
                    "userV2:myBike.hero.navigationMenuItems.bookReturnDate",
                  ),
                },
              ]
            : []),
          {
            icon: <PedalBikeIcon />,
            value: "return-checklist",
            label: t("userV2:myBike.hero.navigationMenuItems.returnChecklist"),
          },
        ]
      : []),
  ];

  const handleMenuSelection = useCallback(
    (value: string) => {
      switch (value) {
        case "calculate-savings":
          navigate("../../" + Path.calculator);
          break;
        case "redeem-bike":
          handleClickRedeemBikeButton();
          break;
        case "return-bike":
          handleClickReturnBikeButton();
          break;
        case "terms-conditions": {
          const pdfPublicUrl =
            benefitDefinition?.currentBenefitDefinitionTerm?.pdfObject
              ?.publicUrl;
          if (pdfPublicUrl) {
            window.open(pdfPublicUrl, "_blank");
          } else {
            setIsTermsModalOpen(true);
          }
          break;
        }
        case "book-return-date":
          window.open(bookReturnFormLink, "_blank");
          break;
        case "return-checklist":
          handleOpenReturnChecklistModal();
          break;
      }
    },
    [
      benefitDefinition?.currentBenefitDefinitionTerm?.pdfObject?.publicUrl,
      handleClickRedeemBikeButton,
      handleClickReturnBikeButton,
      navigate,
    ],
  );

  const theme = useTheme() as Theme;
  const backgroundColor = get(theme.palette, background);

  useEffect(() => {
    const metaTag = document.createElement("meta");
    metaTag.name = "theme-color";
    metaTag.content = backgroundColor;

    document.head.appendChild(metaTag);
    return () => {
      document.head.removeChild(metaTag);
    };
  }, [backgroundColor]);

  return (
    <>
      <Flex justify="space-between" fullWidth>
        <Link to="..">
          <CircularButton
            icon={<ArrowBackIcon />}
            variant="outlined"
            size="md"
          />
        </Link>
        <Select
          options={menuItems}
          slots={{ root: CircularButton }}
          slotProps={{
            root: {
              icon: <MoreIcon />,
              variant: "outlined",
              size: "md",
            } as CircularButtonProps,
          }}
          value=""
          onChange={(_, value) => handleMenuSelection(value!)}
        />
      </Flex>
      <BenefitTermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        title={benefitDefinition?.organisation.name ?? ""}
        content={benefitDefinition?.currentBenefitDefinitionTerm?.content ?? ""}
      />
      {contract ? (
        <>
          <UnusedMaintenanceBudgetModal
            isOpen={isOpenForRedeem}
            onClose={handleCloseModalForRedeem}
            unusedBudget={availableMaintenanceBudget}
            currency={contract.currency}
            navigateTo="redeem-bike"
          />
          <UnusedMaintenanceBudgetModal
            isOpen={isOpenForReturn}
            onClose={handleCloseModalForReturn}
            unusedBudget={availableMaintenanceBudget}
            currency={contract.currency}
            navigateTo="return-bike"
          />
        </>
      ) : null}
    </>
  );
}
