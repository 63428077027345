import { useTranslation } from "react-i18next";

import { useScrollToAnchor } from "@vapaus/common-v2";
import { Flex, Typography } from "@vapaus/ui-v2";

import { ShopsTableCard } from "./components/ShopsTableCard";

export function ShopsTable() {
  const { t } = useTranslation();
  const allShopsRef = useScrollToAnchor("all-shops");
  return (
    <Flex ref={allShopsRef} direction="column" gap="md">
      <Flex direction="column">
        <Typography variant="h2">
          {t("userV2:getABike.shopsTable.title")}
        </Typography>
        <Typography variant="bodyLg">
          {t("userV2:getABike.shopsTable.description")}
        </Typography>
      </Flex>
      <ShopsTableCard />
    </Flex>
  );
}
