import { useOutletContext } from "react-router-dom";

import { BikeBenefitContractRead, Payer } from "@vapaus/api-codegen";
import { toGross } from "@vapaus/utils";

import { useGetReturnProcessPlanInvoicing } from "../../../../../../../api/returnProcess";
import { useReturnProcessContext } from "../../../context/ReturnProcessContext";

export function useDeliveryFee() {
  const {
    pickupAddressDetails,
    preliminaryData,
    isContractEndingSoon,
    returnReason,
  } = useReturnProcessContext();

  const { contract } = useOutletContext<{
    contract: BikeBenefitContractRead;
  }>();

  const shouldFetchDeliveryFee =
    !!preliminaryData &&
    !!returnReason &&
    !!pickupAddressDetails?.address &&
    !!pickupAddressDetails?.postalCode &&
    !!pickupAddressDetails?.city &&
    !!pickupAddressDetails?.country &&
    !!pickupAddressDetails?.phoneNumber &&
    !!pickupAddressDetails?.requestedPickupDate;

  const { data, isFetching } = useGetReturnProcessPlanInvoicing(
    {
      bikeBenefitContractId: contract?.id,
      isEndOfLeaseReturn: isContractEndingSoon,
      leaseEndDate: preliminaryData!.leaseEndDate,
      redeemedAccessoryIds: [],
      pickupDetails: {
        address: pickupAddressDetails.address!,
        postCode: pickupAddressDetails.postalCode!,
        city: pickupAddressDetails.city!,
        country: pickupAddressDetails.country!,
        requestedPickupDate: pickupAddressDetails.requestedPickupDate!,
        phoneNumber: pickupAddressDetails.phoneNumber!,
      },
      returnReason: returnReason!,
    },
    {
      enabled: shouldFetchDeliveryFee,
    },
  );

  const deliveryFeeInvoiceLine = data?.find(
    (invoiceLine) => invoiceLine.lineIn.type === "delivery",
  );

  const deliveryFee =
    deliveryFeeInvoiceLine && deliveryFeeInvoiceLine.payer === Payer.Employee
      ? toGross(
          deliveryFeeInvoiceLine.lineIn.price,
          deliveryFeeInvoiceLine.lineIn.vatRate,
        )
      : 0;

  return {
    deliveryFee,
    isFetching,
  };
}
