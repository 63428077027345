const STORAGE_KEY = "vapaus-closed-announcements";

export function readClosedAnnouncements(): string[] {
  const storedClosedAnnouncements = localStorage.getItem(STORAGE_KEY);
  let closedAnnouncementIds = JSON.parse(storedClosedAnnouncements || "[]");
  if (!Array.isArray(closedAnnouncementIds)) {
    closedAnnouncementIds = [];
  }
  return closedAnnouncementIds;
}

export function writeClosedAnnouncements(closedAnnouncementIds: string[]) {
  const storedClosedAnnouncements = JSON.stringify(closedAnnouncementIds);
  localStorage.setItem(STORAGE_KEY, storedClosedAnnouncements);
}
