import styled from "styled-components";

import { Banner, BannerVariant, Markdown } from "@vapaus/ui-v2";

interface AnnouncementProps {
  variant: BannerVariant;
  content: string;
  onClose?: () => void;
}

export function Announcement({ variant, content, onClose }: AnnouncementProps) {
  return (
    <Banner variant={variant} onClose={onClose}>
      <OverrideMarkdownStyle>
        <Markdown content={content} />
      </OverrideMarkdownStyle>
    </Banner>
  );
}

const OverrideMarkdownStyle = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
`;
