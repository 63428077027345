import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { BikeBenefitContractRead, Currency } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import { Button, Flex, Modal, TextButton, Typography } from "@vapaus/ui-v2";

import vismaPayLogo from "../../../../../../assets/vismaPay.png";

type PayNowButtonProps = {
  price?: number;
  isLoading: boolean;
  handlePayNow: () => void;
};

export function PayNowButton({
  price,
  isLoading,
  handlePayNow,
}: PayNowButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { contract } = useOutletContext<{
    contract: BikeBenefitContractRead;
  }>();
  const formatCurrency = useCurrencyFormat(contract.currency || Currency.Eur);
  const handleClose = () => setIsOpen(false);
  return (
    <>
      <Button onClick={() => setIsOpen(true)} fullWidth>
        {t("userV2:redeemReturnSharedComponents.payNowButton.pay", {
          value: formatCurrency(price),
        })}
      </Button>
      <Modal
        isOpen={isOpen}
        onCancel={handleClose}
        onClose={handleClose}
        cancelText={t("commonV2:cancel")}
        confirmText={t("commonV2:continue")}
        onConfirm={handlePayNow}
        isSubmitting={isLoading}
      >
        <Flex direction="column" align="center" gap="md">
          <img src={vismaPayLogo} alt="Visma Pay Logo" />
          <Typography variant="bodyLg" align="center">
            {t("userV2:billingTable.paymentInstructions")}
          </Typography>
          <TextButton
            to={t("userV2:billingTable.termsOfServiceLink")}
            target="_blank"
          >
            {t("userV2:billingTable.termsOfService")}
          </TextButton>
        </Flex>
      </Modal>
    </>
  );
}
