import React from "react";

import { TFunction } from "i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { ShopType, UserShopRead } from "@vapaus/api-codegen";

import {
  amplerShopId,
  bikesterShopId,
  canyonShopId,
  commencalShopId,
  econicOneShopId,
  silverbackShopId,
  verkkokauppaShopId,
} from "../hardCodedShopIds";

export function getShopDetails(
  shop: UserShopRead,
  t: TFunction<"translation">,
) {
  if (
    [econicOneShopId, commencalShopId, verkkokauppaShopId].includes(shop.id)
  ) {
    return {
      description: (
        <Trans
          i18nKey={"userV2:getABike.partnerShops.vapausOrderForm.description"}
          components={{
            1: (
              <Link
                target="_blank"
                rel="noreferrer"
                to={t("userV2:getABike.partnerShops.vapausOrderForm.formLink")}
              >
                order form
              </Link>
            ),
          }}
        />
      ),
      instructionsLink: t(
        "userV2:getABike.partnerShops.vapausOrderForm.instructionsLink",
      ),
      formLink: t("userV2:getABike.partnerShops.vapausOrderForm.formLink"),
    };
  }
  if (shop.id === amplerShopId) {
    return {
      description: t("userV2:getABike.partnerShops.emailOrder.description"),
      instructionsLink: t(
        "userV2:getABike.partnerShops.emailOrder.ampler.instructionsLink",
      ),
    };
  }
  if (shop.id === bikesterShopId) {
    return {
      description: t("userV2:getABike.partnerShops.emailOrder.description"),
      instructionsLink: t(
        "userV2:getABike.partnerShops.emailOrder.bikester.instructionsLink",
      ),
    };
  }
  if (shop.id === silverbackShopId) {
    return {
      description: t("userV2:getABike.partnerShops.emailOrder.description"),
      instructionsLink: t(
        "userV2:getABike.partnerShops.emailOrder.silverback.instructionsLink",
      ),
    };
  }
  if (shop.id === canyonShopId) {
    return {
      description: (
        <Trans
          i18nKey={"userV2:getABike.partnerShops.canyon.description"}
          components={{
            1: (
              <Link
                target="_blank"
                rel="noreferrer"
                to={t("userV2:getABike.partnerShops.canyon.formLink")}
              >
                order form
              </Link>
            ),
          }}
        />
      ),
      instructionsLink: t(
        "userV2:getABike.partnerShops.canyon.instructionsLink",
      ),
      formLink: t("userV2:getABike.partnerShops.canyon.formLink"),
    };
  }
  if (shop.types.length === 1 && shop.types[0] === ShopType.Maintenance) {
    return {
      description: t(
        "userV2:getABike.partnerShops.maintenanceOnly.description",
      ),
    };
  }
  return {
    description: t("userV2:getABike.partnerShops.default.description"),
  };
}
