import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Currency } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import { Card, Flex, Notification, Typography } from "@vapaus/ui-v2";

import { Path } from "../../../../../../../constants/paths";
import { ContractSummaryCardProps } from "../ContractSummaryCard";
import { OrderInfoSection } from "./OrderInfoSection";
import { SummarySection } from "./SummarySection";

type ContractSummaryCardDefaultProps = Omit<
  ContractSummaryCardProps,
  "swedenSuggestedBenefitValues"
>;

export function ContractSummaryCardDefault({
  benefitDefinition,
  redemptionPrice,
  residualRatio,
  initialMonthlyTaxableValue,
  monthlyTaxableValue,
  leasingPeriodMonths,
  monthlyMaintenanceBudget,
  downPaymentAmount,
  bikeOrderPrice,
  leasingCost,
  children,
}: ContractSummaryCardDefaultProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(
    benefitDefinition?.currency || Currency.Eur,
  );

  const totalPayment = (initialMonthlyTaxableValue || 0) * leasingPeriodMonths;

  return (
    <Card title={t("userV2:myBike.contractSummaryCard.title")}>
      <Typography marginBottom="lg">
        <Trans
          i18nKey="userV2:myBike.contractSummaryCard.description"
          values={{
            amount: formatCurrency(totalPayment),
            monthlyValue: formatCurrency(initialMonthlyTaxableValue),
            durationMonths: leasingPeriodMonths,
            residualValue: formatCurrency(redemptionPrice),
          }}
          components={{
            b: <b />,
          }}
        />
        <>
          {" "}
          <br />
          <br />
          <Trans
            i18nKey="userV2:myBike.contractSummaryCard.calculatorLink"
            components={{
              a: <Link to={`/${Path.calculator}`} target="_blank" />,
            }}
          />
        </>
      </Typography>
      <Flex direction="column" gap="lg">
        {initialMonthlyTaxableValue !== monthlyTaxableValue && (
          <Notification type="warning">
            <Typography
              variant="bodySm"
              weight="bold"
              marginBottom="sm"
              fullWidth
            >
              {t(
                "userV2:myBike.contractSummaryCard.vatRateChangeNotification.title",
              )}
            </Typography>
            <Typography variant="bodySm" marginBottom="sm">
              <Trans
                i18nKey="userV2:myBike.contractSummaryCard.vatRateChangeNotification.description"
                components={{
                  b: <b />,
                }}
                values={{
                  monthlyTaxableValue: formatCurrency(monthlyTaxableValue),
                  redemptionPrice: formatCurrency(redemptionPrice),
                }}
              />
            </Typography>
            <Typography variant="bodySm">
              <a
                href="https://www.vapaus.io/tyontekijalle/alv-korotus-vaikuttaa-pyoraetuun"
                target="_blank"
                rel="noreferrer"
              >
                {t(
                  "userV2:myBike.contractSummaryCard.vatRateChangeNotification.learnMore",
                )}
              </a>
            </Typography>
          </Notification>
        )}
        <Flex direction="column" gap="md">
          <OrderInfoSection
            benefitDefinition={benefitDefinition}
            leasingPeriodMonths={leasingPeriodMonths}
            monthlyMaintenanceBudget={monthlyMaintenanceBudget}
            downPaymentAmount={downPaymentAmount}
            bikeOrderPrice={bikeOrderPrice}
            leasingCost={leasingCost}
          />
          <SummarySection
            benefitDefinition={benefitDefinition}
            redemptionPrice={redemptionPrice}
            residualRatio={residualRatio}
            initialMonthlyTaxableValue={initialMonthlyTaxableValue}
            leasingPeriodMonths={leasingPeriodMonths}
            totalPayment={totalPayment}
            children={children}
          />
        </Flex>
      </Flex>
    </Card>
  );
}
