import styled, { css, keyframes } from "styled-components";

import { addAlpha } from "../styles";

interface SkeletonProps {
  variant?: "text" | "rectangular" | "circular";
  width?: string | number;
  height?: string | number;
  animation?: "pulse" | "wave" | "none";
  className?: string;
}

export function Skeleton({
  variant = "text",
  width,
  height,
  animation = "pulse",
  className,
}: SkeletonProps) {
  return (
    <SkeletonWrapper
      variant={variant}
      width={width}
      height={height}
      animation={animation}
      className={className}
    />
  );
}

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const waveAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const SkeletonWrapper = styled.div<SkeletonProps>`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border-radius: ${(props) => (props.variant === "circular" ? "50%" : "4px")};
  width: ${(props) =>
    typeof props.width === "number"
      ? `${props.width}px`
      : props.width || "100%"};
  height: ${(props) =>
    typeof props.height === "number"
      ? `${props.height}px`
      : props.height || "auto"};
  display: inline-block;
  line-height: 1;
  position: relative;
  overflow: hidden;

  ${({ animation, theme }) =>
    animation === "pulse" &&
    css`
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: ${addAlpha(theme.palette.background.white, 0.6)};
        animation: ${pulseAnimation} 1.5s infinite;
      }
    `}

  ${({ animation, theme }) =>
    animation === "wave" &&
    css`
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          90deg,
          transparent,
          ${addAlpha(theme.palette.background.white, 0.6)},
          transparent
        );
        animation: ${waveAnimation} 1.5s linear infinite;
      }
    `}
`;
