import { useTranslation } from "react-i18next";

import { BikeBenefitContractRead } from "@vapaus/api-codegen";
import { Split } from "@vapaus/common-v2";
import { useCurrencyFormat } from "@vapaus/i18n";
import { Typography } from "@vapaus/ui-v2";

interface ContractSplitProps {
  contract: BikeBenefitContractRead;
}

export function ContractSplit({ contract }: ContractSplitProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(contract.currency);

  const monthsLeft = contract.remainingLeasingPeriodMonths;
  const effectiveTaxableValue =
    contract.fixedMonthlyTaxableValue || contract.monthlyTaxableValue;
  const effectiveInitialTaxableValue =
    contract.initialFixedMonthlyTaxableValue ||
    contract.initialMonthlyTaxableValue;
  const totalToPay =
    effectiveInitialTaxableValue * contract.leasingPeriodMonths;
  const leftToPay = effectiveTaxableValue * monthsLeft;

  return (
    <Split
      leftContent={
        <>
          <Typography variant="bodyXs" noWrap>
            {t("userV2:contractSplit.left.row1")}
          </Typography>
          <Typography variant="h4" noWrap>
            {formatCurrency(leftToPay)}
          </Typography>
          <Typography variant="bodyXs" noWrap>
            {t("userV2:contractSplit.left.row2", {
              value: formatCurrency(totalToPay),
            })}
          </Typography>
        </>
      }
      rightContent={
        <>
          <Typography variant="bodyXs" noWrap>
            {t("userV2:contractSplit.right.row1")}
          </Typography>
          <Typography variant="h4" noWrap>
            {t("userV2:contractSplit.right.row2", { value: monthsLeft })}
          </Typography>
          <Typography variant="bodyXs" noWrap>
            {t("userV2:contractSplit.right.row3", {
              value: contract.leasingPeriodMonths,
            })}
          </Typography>
        </>
      }
    />
  );
}
