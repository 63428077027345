import { Outlet } from "react-router-dom";
import styled, { css } from "styled-components";

import { BenefitDefinitionAccessRequestStatus } from "@vapaus/api-codegen";

import { useGetBenefitDefinition } from "../../api/benefitDefinitions";
import { BenefitHero } from "./components/BenefitHero";

type BenefitPageProps = {
  benefitId: string;
  benefitDefinitionId: string;
  status?: BenefitDefinitionAccessRequestStatus;
  isActivationActive?: boolean;
  businessId?: string;
};

export function BenefitPage({
  benefitId,
  benefitDefinitionId,
  status,
  isActivationActive,
  businessId,
}: BenefitPageProps) {
  const { data: benefitDefinition } =
    useGetBenefitDefinition(benefitDefinitionId);

  if (benefitDefinitionId && !benefitDefinition) {
    return null;
  }

  return (
    <div>
      <BenefitHero
        benefitId={benefitId}
        benefitDefinition={benefitDefinition}
        status={status}
        isActivationActive={isActivationActive}
        businessId={businessId}
      />
      <StyledRoot>
        <StyledContainer>
          <Outlet context={{ benefitDefinition, businessId }} />
        </StyledContainer>
      </StyledRoot>
    </div>
  );
}

const StyledRoot = styled.div`
  margin: ${({ theme }) => theme.spacing.lg} ${({ theme }) => theme.spacing.xl};

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      margin: ${({ theme }) => theme.spacing.md};
    `)}
`;

const StyledContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
