import { useTranslation } from "react-i18next";

import { Currency, Plan } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import { Flex, Paper, Separator, Tooltip, Typography } from "@vapaus/ui-v2";

import { NameValueDescription } from "../../../../../../../components/NameValueDescription";
import { ContractSummaryCardProps } from "../ContractSummaryCard";

type SummarySectionProps = Pick<
  ContractSummaryCardProps,
  | "benefitDefinition"
  | "redemptionPrice"
  | "residualRatio"
  | "initialMonthlyTaxableValue"
  | "leasingPeriodMonths"
  | "children"
> & { totalPayment: number; isSwedishContract?: boolean };

export function SummarySection({
  benefitDefinition,
  redemptionPrice,
  residualRatio,
  initialMonthlyTaxableValue,
  leasingPeriodMonths,
  totalPayment,
  children,
  isSwedishContract = false,
}: SummarySectionProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(
    benefitDefinition?.currency || Currency.Eur,
  );
  return (
    <Paper background="secondary1.lighter" padding="lg">
      <Flex direction="column" gap="lg">
        <Flex direction="column" gap="sm">
          <Flex justify="space-between">
            <Typography weight="bold">
              {t("userV2:myBike.contractSummaryCard.total")}
            </Typography>
            <Flex gap="xs">
              <Typography weight="bold">
                {formatCurrency(totalPayment)}
              </Typography>
              {benefitDefinition &&
                ([Plan.Custom, Plan.Legacy] as Plan[]).includes(
                  benefitDefinition.plan,
                ) && (
                  <Tooltip>
                    {t("userV2:myBike.contractSummaryCard.totalLegacyInfo")}
                  </Tooltip>
                )}
              {isSwedishContract ? (
                <Tooltip>
                  {t("userV2:myBike.contractSummaryCard.sweden.total.tooltip", {
                    leasingPeriodMonths,
                  })}
                </Tooltip>
              ) : null}
            </Flex>
          </Flex>
          <Flex justify="space-between">
            <Typography weight="bold">
              {isSwedishContract
                ? t(
                    "userV2:myBike.contractSummaryCard.sweden.monthlyRentalFee.label",
                  )
                : t("userV2:myBike.contractSummaryCard.monthlyPayment")}
            </Typography>
            <Flex gap="xs">
              <Typography>
                {formatCurrency(initialMonthlyTaxableValue)}
              </Typography>
              {isSwedishContract ? (
                <Tooltip>
                  {t(
                    "userV2:myBike.contractSummaryCard.sweden.monthlyRentalFee.tooltip",
                  )}
                </Tooltip>
              ) : null}
            </Flex>
          </Flex>
          <Flex justify="space-between">
            <Typography weight="bold">
              {t("userV2:myBike.contractSummaryCard.contractLength")}
            </Typography>
            <Typography>
              {t("userV2:myBike.contractSummaryCard.contractLengthValue", {
                value: leasingPeriodMonths,
              })}
            </Typography>
          </Flex>
        </Flex>
        <Separator />
        <NameValueDescription
          name={t("userV2:myBike.contractSummaryCard.redemptionPrice.name")}
          value={formatCurrency(redemptionPrice)}
          description={t(
            "userV2:myBike.contractSummaryCard.redemptionPrice.description",
            {
              residualRatio: (residualRatio || 0) * 100,
            },
          )}
        />
        {children}
      </Flex>
    </Paper>
  );
}
