import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { UserShopRead } from "@vapaus/api-codegen";
import { Flex, RowItem, TextButton, Typography } from "@vapaus/ui-v2";

import { extractHostnameFromUrl } from "../../../../../../../../../../../utils/extractHostnameFromUrl";
import { getAddressString } from "../../../utils/getAddressString";

type ContactSectionProps = {
  shop: UserShopRead;
};

export function ContactInfoSection({ shop }: ContactSectionProps) {
  const { t } = useTranslation();

  return (
    <Flex direction="column">
      {shop.website ? (
        <RowItem
          label={t("userV2:getABike.partnerShops.website")}
          value={
            <TextButton size="md" to={shop.website} target="_blank">
              {extractHostnameFromUrl(shop.website)}
            </TextButton>
          }
        />
      ) : null}
      {shop.phoneNumber ? (
        <RowItem
          label={t("userV2:getABike.partnerShops.phoneNumber")}
          value={
            <TextButton size="md" to={`tel:${shop.phoneNumber}`} align="right">
              {shop.phoneNumber}
            </TextButton>
          }
        />
      ) : null}
      {shop.email ? (
        <RowItem
          label={t("userV2:getABike.partnerShops.emailAddress")}
          value={
            <TextButton size="md" to={`mailto:${shop.email}`} align="right">
              {shop.email}
            </TextButton>
          }
        />
      ) : null}
      {shop.streetAddress ? (
        <RowItem
          align="flex-start"
          label={t("userV2:getABike.partnerShops.address")}
          value={
            <TextButton
              to={`https://www.google.com/maps/place/${getAddressString(shop)}`}
              target="_blank"
            >
              <StyledFlex direction="column" gap="none">
                <Typography align="right" fullWidth weight="bold">
                  {shop.streetAddress}
                </Typography>
                <Typography align="right" fullWidth weight="bold">
                  {shop.postalCode} {shop.city}
                </Typography>
              </StyledFlex>
            </TextButton>
          }
        />
      ) : null}
    </Flex>
  );
}

const StyledFlex = styled(Flex)`
  :hover > p {
    color: ${({ theme }) => theme.palette.primary1.light};
  }
`;
