import { useTranslation } from "react-i18next";

import { Currency } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";

import { NameValueDescription } from "../../../../../../../components/NameValueDescription";
import { ContractSummaryCardProps } from "../ContractSummaryCard";

type OrderInfoSectionProps = Pick<
  ContractSummaryCardProps,
  | "benefitDefinition"
  | "leasingPeriodMonths"
  | "monthlyMaintenanceBudget"
  | "downPaymentAmount"
  | "bikeOrderPrice"
  | "leasingCost"
>;

export function OrderInfoSection({
  benefitDefinition,
  leasingPeriodMonths,
  monthlyMaintenanceBudget,
  downPaymentAmount,
  bikeOrderPrice,
  leasingCost,
}: OrderInfoSectionProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(
    benefitDefinition?.currency || Currency.Eur,
  );
  return (
    <>
      <NameValueDescription
        name={t("userV2:myBike.contractSummaryCard.bikeOrder.name")}
        value={formatCurrency(bikeOrderPrice)}
        description={t(
          "userV2:myBike.contractSummaryCard.bikeOrder.description",
        )}
      />
      <NameValueDescription
        name={t("userV2:myBike.contractSummaryCard.maintenance.name")}
        value={`+${formatCurrency(
          monthlyMaintenanceBudget * leasingPeriodMonths,
        )}`}
        description={
          monthlyMaintenanceBudget > 0
            ? t("userV2:myBike.contractSummaryCard.maintenance.description", {
                value: formatCurrency(monthlyMaintenanceBudget),
              })
            : t(
                "userV2:myBike.contractSummaryCard.maintenance.descriptionNoMaintenanceBudget",
                { value: formatCurrency(monthlyMaintenanceBudget) },
              )
        }
      />
      {!!downPaymentAmount && (
        <NameValueDescription
          name={t("userV2:myBike.contractSummaryCard.downPayment.name")}
          value={formatCurrency(-downPaymentAmount)}
          description={t(
            "userV2:myBike.contractSummaryCard.downPayment.description",
          )}
        />
      )}
      <NameValueDescription
        name={t("userV2:myBike.contractSummaryCard.leasingCosts.name")}
        value={`+${formatCurrency(leasingCost)}`}
        description={t(
          "userV2:myBike.contractSummaryCard.leasingCosts.description",
        )}
      />
    </>
  );
}
