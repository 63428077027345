import { useEffect, useState } from "react";

import {
  AvailableScopes,
  SupportedCountriesEnum,
  SupportedLanguagesEnum,
} from "@vapaus/api-codegen";

import { Announcement } from "./Announcement";
import { useGetAnnouncements } from "./useAnnouncement";
import { readClosedAnnouncements, writeClosedAnnouncements } from "./utils";

interface AnnouncementsProps {
  country: SupportedCountriesEnum;
  scope: AvailableScopes;
  language: SupportedLanguagesEnum;
}

export function Announcements({
  scope,
  country,
  language,
}: AnnouncementsProps) {
  const { data: announcements } = useGetAnnouncements({ scope, country });
  const [closedAnnouncementIds, setClosedAnnouncementIds] = useState(
    readClosedAnnouncements(),
  );

  useEffect(() => {
    writeClosedAnnouncements(closedAnnouncementIds);
  }, [closedAnnouncementIds]);

  if (!announcements) return null;

  const filteredAnnouncements = announcements.filter(
    (announcement) => !closedAnnouncementIds.includes(announcement.id),
  );

  if (filteredAnnouncements.length < 1) return null;

  const handleClose = (announcementId: string) => {
    setClosedAnnouncementIds((closedAnnouncementIds) => [
      ...closedAnnouncementIds,
      announcementId,
    ]);
  };

  return (
    <div>
      {filteredAnnouncements.map((announcement) => (
        <Announcement
          key={announcement.id}
          content={announcement.content[language] ?? announcement.content["EN"]}
          variant={announcement.variant}
          onClose={
            announcement.isCloseable
              ? () => handleClose(announcement.id)
              : undefined
          }
        />
      ))}
    </div>
  );
}
