import { useEffect, useRef } from "react";

import { useLocation } from "react-router-dom";

export function useScrollToAnchor(
  hashKey: string,
  block: ScrollLogicalPosition = "start",
): React.RefObject<HTMLDivElement> {
  const elementRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const locationKey = location.key;
  const locationHash = location.hash;

  useEffect(() => {
    if (elementRef.current && locationHash === `#${hashKey}`) {
      elementRef.current.scrollIntoView({
        behavior: "smooth",
        block,
      });
    }
  }, [hashKey, locationHash, locationKey, block]);

  return elementRef;
}
