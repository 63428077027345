import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ShopType, UserShopRead } from "@vapaus/api-codegen";
import {
  BuildIcon,
  CircularIcon,
  Flex,
  ShoppingCartIcon,
  StorefrontIcon,
  Typography,
} from "@vapaus/ui-v2";

import { mapShopType } from "../../../utils/mapShopType";

type ServicesSectionProps = {
  shop: UserShopRead;
};

export function ServicesSection({ shop }: ServicesSectionProps) {
  const { t } = useTranslation();

  const getIcon = (type: ShopType) =>
    type === ShopType.LocalStore
      ? StorefrontIcon
      : type === ShopType.OnlineStore
      ? ShoppingCartIcon
      : BuildIcon;

  return (
    <Flex direction="column">
      <Typography weight="bold">
        {t("userV2:getABike.partnerShops.services")}
      </Typography>
      {shop.types.map((type) => (
        <Flex gap="xs" key={type}>
          <StyledCircularIconContainer>
            <CircularIcon icon={getIcon(type)} />
          </StyledCircularIconContainer>
          <Typography variant="bodySm" weight="bold">
            {mapShopType(type, t)}
          </Typography>
        </Flex>
      ))}
    </Flex>
  );
}

const StyledCircularIconContainer = styled.div`
  > div {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
  }

  i {
    font-size: 1.25rem;
  }
`;
