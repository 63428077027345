import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import {
  BenefitDefinitionUserRead,
  BikeBenefitContractRead,
  BikeBenefitOrderRead,
} from "@vapaus/api-codegen";
import { useDateFormat } from "@vapaus/i18n";
import { Button, Card, Flex, HorizontalLine, Typography } from "@vapaus/ui-v2";

import { OrderStatus } from "../../../../../components/OrderStatus";
import { CancelContractInfo } from "./CancelContractInfo";

interface StatusCardProps {
  order: BikeBenefitOrderRead;
  contract: BikeBenefitContractRead;
  benefitDefinition?: BenefitDefinitionUserRead;
  onModalOpen: () => void;
}

export function StatusCard({
  order,
  contract,
  benefitDefinition,
  onModalOpen,
}: StatusCardProps) {
  const { t } = useTranslation();
  const formatDate = useDateFormat();

  const onBenefitGuidelineButtonClick = () =>
    benefitDefinition?.currentBenefitDefinitionTerm?.content
      ? onModalOpen()
      : window.open(
          benefitDefinition?.currentBenefitDefinitionTerm?.pdfObject?.publicUrl,
        );

  return (
    <Card title={t("userV2:myBike.contractPanel.status.title")}>
      <Flex direction="column" gap="lg">
        <Flex direction="column" gap="sm">
          <Flex justify="space-between">
            <Typography weight="bold">
              {t("userV2:myBike.contractPanel.status.status")}
            </Typography>
            <OrderStatus order={order} contract={contract} />
          </Flex>
          <Flex justify="space-between">
            <Typography weight="bold">
              {t("userV2:myBike.contractPanel.status.employer")}
            </Typography>
            <Typography>{benefitDefinition?.organisation.name}</Typography>
          </Flex>
          <Flex justify="space-between">
            <Typography weight="bold">
              {t("userV2:myBike.contractPanel.status.benefit")}
            </Typography>
            <Typography>{benefitDefinition?.name}</Typography>
          </Flex>
          <Flex justify="space-between">
            <Typography weight="bold">
              {t("userV2:myBike.contractPanel.status.startDate")}
            </Typography>
            <Typography>
              {formatDate(contract.leasingPeriodStartDate)}
            </Typography>
          </Flex>
          <Flex justify="space-between">
            <Typography weight="bold">
              {t("userV2:myBike.contractPanel.status.plannedEndDate")}
            </Typography>
            <Typography>{formatDate(contract.endDate)}</Typography>
          </Flex>
          <Flex justify="space-between">
            <Typography weight="bold">
              {t("userV2:myBike.contractPanel.status.actualEndDate")}
            </Typography>
            <Typography>
              {formatDate(
                contract.exceptionalEndDate
                  ? contract.exceptionalEndDate
                  : DateTime.fromJSDate(contract.endDate) < DateTime.now()
                  ? contract.endDate
                  : undefined,
              )}
            </Typography>
          </Flex>
        </Flex>
        <HorizontalLine marginBottom="none" marginTop="none" />
        <Flex
          xsDirection="column"
          gap="md"
          justify="space-between"
          xsAlign="stretch"
        >
          <Flex direction="column" gap="xs">
            <Typography weight="bold">
              {t("userV2:myBike.contractPanel.status.benefitGuideline.title")}
            </Typography>
            <Typography>
              {t(
                "userV2:myBike.contractPanel.status.benefitGuideline.description",
              )}
            </Typography>
          </Flex>
          <Button onClick={onBenefitGuidelineButtonClick} size="small">
            {t("userV2:myBike.contractPanel.status.benefitGuideline.button")}
          </Button>
        </Flex>
        <CancelContractInfo contract={contract} />
      </Flex>
    </Card>
  );
}
