import { Trans, useTranslation } from "react-i18next";

import { Form, PasswordInput } from "@vapaus/form";
import {
  Button,
  Flex,
  Notification,
  PageSpinner,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import phoneKeyImg from "../../../assets/Phone_Key.png";
import { AuthLayout } from "../AuthLayout";
import { useResetPasswordPage } from "./useResetPasswordPage";

export function ResetPasswordPage() {
  const { t } = useTranslation();
  const { formMethods, mutation, isLoading } = useResetPasswordPage();
  const rootErrorMessage = formMethods.formState.errors.root?.message;

  if (isLoading) return <PageSpinner />;

  return (
    <AuthLayout
      title={t("authV2:resetPassword.title")}
      description={t("authV2:resetPassword.description")}
      bannerImage={phoneKeyImg}
      bgColor="secondary1.main"
    >
      <Form formMethods={formMethods}>
        <Flex direction="column" gap="sm">
          {rootErrorMessage && (
            <Notification message={rootErrorMessage} type="error" />
          )}
          <PasswordInput
            name="newPassword"
            label={t("authV2:createPassword.createPassword")}
            placeholder={t("authV2:createPassword.passwordPlaceholder")}
          />
          <Button
            variant="primary1"
            marginBottom="xs"
            marginTop="xs"
            isLoading={mutation.isLoading}
            fullWidth
          >
            {t("authV2:resetPassword.changePassword")}
          </Button>
          <Typography variant="bodyLg" align="center">
            <Trans t={t} i18nKey="authV2:forgotPassword.rememberYourPassword">
              <span>Remember your password?</span>
              <TextButton to="/sign-in">Log in</TextButton>
            </Trans>
          </Typography>
        </Flex>
      </Form>
    </AuthLayout>
  );
}
