import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Currency } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import { Modal } from "@vapaus/ui-v2";

import { CONTRACT_CANCELLATION_FROM_STATE } from "../../../../../../constants/contractNavigation";

type UnusedMaintenanceBudgetModalProps = {
  isOpen: boolean;
  onClose: () => void;
  currency: Currency;
  unusedBudget: number;
  navigateTo: "redeem-bike" | "return-bike";
};

export function UnusedMaintenanceBudgetModal({
  onClose,
  isOpen,
  unusedBudget,
  currency,
  navigateTo,
}: UnusedMaintenanceBudgetModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formatCurrent = useCurrencyFormat(currency || Currency.Eur);

  const onConfirm = () => {
    onClose();
    navigate(navigateTo, {
      state: { navigatedFrom: CONTRACT_CANCELLATION_FROM_STATE },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={onConfirm}
      title={t("userV2:redeemReturnSharedComponents.unusedBudgetModal.title")}
      description={
        navigateTo === "return-bike" ? (
          <Trans
            t={t}
            i18nKey="userV2:redeemReturnSharedComponents.unusedBudgetModal.descriptionReturn"
            values={{
              value: formatCurrent(unusedBudget),
            }}
            components={{ 1: <b /> }}
          />
        ) : (
          <Trans
            t={t}
            i18nKey="userV2:redeemReturnSharedComponents.unusedBudgetModal.descriptionRedeem"
            values={{
              value: formatCurrent(unusedBudget),
            }}
            components={{ 1: <b /> }}
          />
        )
      }
      cancelText={t("commonV2:cancel")}
      confirmText={t("commonV2:gotIt")}
    />
  );
}
