import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { BikeBenefitContractRead } from "@vapaus/api-codegen";
import { Button, Flex } from "@vapaus/ui-v2";

import { UnusedMaintenanceBudgetModal } from "./components/UnusedMaintenanceBudgetModal";
import { useRedeemReturnCTA } from "./hooks/useRedeemReturnCTA";

type RedeemReturnCTAButtonsProps = {
  contract?: BikeBenefitContractRead;
};

export function RedeemReturnCTAButtons({
  contract,
}: RedeemReturnCTAButtonsProps) {
  const { t } = useTranslation();
  const {
    handleClickRedeemBikeButton,
    handleClickReturnBikeButton,
    handleCloseModalForRedeem,
    handleCloseModalForReturn,
    isOpenForRedeem,
    isOpenForReturn,
    availableMaintenanceBudget,
    hasActiveCancellationCoverage,
    isContractEndingSoon,
  } = useRedeemReturnCTA(contract);

  if (!contract) return null;

  return (
    <StyledRoot justify="center" xsDirection="column">
      <StyledButtonWrapper>
        <Button size="small" onClick={handleClickRedeemBikeButton} fullWidth>
          {t(
            "userV2:redeemReturnSharedComponents.optionSelection.redeemBikeButton.title",
          )}
        </Button>
      </StyledButtonWrapper>
      {hasActiveCancellationCoverage || isContractEndingSoon ? (
        <StyledButtonWrapper>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClickReturnBikeButton}
            fullWidth
          >
            {t(
              "userV2:redeemReturnSharedComponents.optionSelection.returnBikeButton.title",
            )}
          </Button>
        </StyledButtonWrapper>
      ) : null}
      <UnusedMaintenanceBudgetModal
        isOpen={isOpenForRedeem}
        onClose={handleCloseModalForRedeem}
        unusedBudget={availableMaintenanceBudget}
        currency={contract.currency}
        navigateTo="redeem-bike"
      />
      <UnusedMaintenanceBudgetModal
        isOpen={isOpenForReturn}
        onClose={handleCloseModalForReturn}
        unusedBudget={availableMaintenanceBudget}
        currency={contract.currency}
        navigateTo="return-bike"
      />
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 100%;
    `)}
`;

const StyledButtonWrapper = styled.div`
  min-width: fit-content;
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 100%;
    `)}
`;
