import styled from "styled-components";

import { UserShopRead } from "@vapaus/api-codegen";
import { CircularIcon, Flex, StorefrontIcon, Typography } from "@vapaus/ui-v2";

import { extractHostnameFromUrl } from "../../../../../../../utils/extractHostnameFromUrl";

type ShopNameProps = {
  shop: UserShopRead;
};

export function ShopName({ shop }: ShopNameProps) {
  return (
    <Flex>
      {shop?.logoUrl ? (
        <StyledLogoWrapper>
          <StyledLogoImage src={shop.logoUrl} alt="shop-logo" />
        </StyledLogoWrapper>
      ) : (
        <CircularIcon icon={StorefrontIcon} />
      )}
      <Flex direction="column" gap="none">
        <Typography weight="bold">{shop.name}</Typography>
        {shop.website ? (
          <Typography>{extractHostnameFromUrl(shop.website)}</Typography>
        ) : null}
      </Flex>
    </Flex>
  );
}

const StyledLogoWrapper = styled.div`
  border: ${({ theme }) => theme.border.primary.main};
  background: ${({ theme }) => theme.palette.background.white};
  height: ${({ theme }) => theme.icon.fontSizes.lg};
  width: ${({ theme }) => theme.icon.fontSizes.lg};
  min-height: ${({ theme }) => theme.icon.fontSizes.lg};
  min-width: ${({ theme }) => theme.icon.fontSizes.lg};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
`;

const StyledLogoImage = styled.img`
  height: ${({ theme }) => theme.icon.fontSizes.lg};
  width: ${({ theme }) => theme.icon.fontSizes.lg};
`;
