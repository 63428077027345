import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { UserShopRead } from "@vapaus/api-codegen";
import { Flex, TextButton, Typography } from "@vapaus/ui-v2";

import { getShopDetails } from "../../utils/getShopDetails";
import { mapShopType } from "../../utils/mapShopType";
import { ButtonsSection } from "./components/ButtonsSection";
import { ShopLogo } from "./components/ShopLogo";

type ShopDetailsHeaderSectionProps = {
  shop: UserShopRead;
};

export function ShopDetailsHeaderSection({
  shop,
}: ShopDetailsHeaderSectionProps) {
  const { t } = useTranslation();

  const shopDetails = getShopDetails(shop, t);

  return (
    <StyledRoot>
      <Flex direction="column" align="center" gap="md">
        <Flex direction="column" align="center">
          <ShopLogo logoUrl={shop.logoUrl} />
          <Flex direction="column" align="center" gap="none">
            <Typography variant="h1" align="center">
              {shop.name}
            </Typography>
            <Typography weight="bold" align="center">
              {shop.types.map((type) => mapShopType(type, t)).join(" · ")}
            </Typography>
          </Flex>
          <Typography variant="bodyLg" align="center">
            {shopDetails.description}
          </Typography>
        </Flex>
        <ButtonsSection shop={shop} />
        {shopDetails.instructionsLink ? (
          <TextButton
            to={shopDetails.instructionsLink}
            target="_blank"
            size="md"
          >
            {t("userV2:getABike.partnerShops.viewDetailedInstructions")}
          </TextButton>
        ) : null}
      </Flex>
    </StyledRoot>
  );
}

const StyledRoot = styled.div`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border-bottom: ${({ theme }) => theme.border.secondary.main};
  padding: ${({ theme }) => theme.spacing.xl};
  padding-top: ${({ theme }) => theme.spacing.xxl};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding: 0 ${({ theme }) => theme.spacing.md}
        ${({ theme }) => theme.spacing.xl};
    `)}
`;
