import { MouseEventHandler, ReactNode, forwardRef } from "react";
import { createPortal } from "react-dom";

import { Modal as BaseModal } from "@mui/base/Modal";
import { animated, useSpring } from "@react-spring/web";
import styled from "styled-components";

import { useForwardedRef } from "@vapaus/utils";

import { CircularButton } from "../CircularButton";
import { CloseIcon } from "../Icons";
import { Modal } from "../Modal";
import { useBreakpoints } from "../styles";

export type DrawerProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export function Drawer({ children, isOpen, onClose }: DrawerProps) {
  const { isMobile } = useBreakpoints();

  const overlayStyles = useSpring({
    opacity: isOpen ? 0.5 : 0,
    config: { tension: 210, friction: 20, duration: 200 },
  });

  if (isMobile) {
    return (
      <Modal
        shouldContentHavePadding={false}
        topBarBgColor="background.secondary"
        isOpen={isOpen}
        onClose={onClose}
      >
        {children}
      </Modal>
    );
  }

  if (!isOpen) return null;

  return createPortal(
    <>
      <StyledRoot
        data-testid="drawer-modal-root"
        open={isOpen}
        onClose={onClose}
      >
        <Slide in={isOpen}>
          <StyledModalBox>
            <StyledCloseButtonWrapper>
              <CircularButton
                onClick={onClose}
                icon={<CloseIcon />}
                variant="outlined"
                size="md"
              />
            </StyledCloseButtonWrapper>
            {children}
          </StyledModalBox>
        </Slide>
      </StyledRoot>
      <Overlay onClick={onClose} style={overlayStyles} />
    </>,
    document.body,
  );
}

const StyledRoot = styled(BaseModal)`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

const Overlay = animated(styled.div`
  background-color: ${({ theme }) => theme.palette.primary1.main};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`);

interface SlideProps {
  children: ReactNode;
  in?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
}

const Slide = forwardRef<HTMLDivElement, SlideProps>(
  function Slide(props, ref) {
    const { in: isOpen, children, onEnter, onExited, ...other } = props;
    const innerRef = useForwardedRef<HTMLDivElement>(ref);
    const style = useSpring({
      from: { transform: "translateX(100%)" },
      to: { transform: isOpen ? "translateX(0%)" : "translateX(100%)" },
      onStart: () => {
        if (isOpen && onEnter) {
          onEnter(innerRef.current as HTMLDivElement, true);
        }
      },
      onRest: () => {
        if (!isOpen && onExited) {
          onExited(innerRef.current as HTMLDivElement, true);
        }
      },
      config: { duration: 300 },
    });

    return (
      <animated.div
        ref={ref}
        style={{
          ...style,
          maxWidth: 600,
          width: "100%",
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
        }}
        {...other}
      >
        {children}
      </animated.div>
    );
  },
);

const StyledModalBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

const StyledCloseButtonWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  position: fixed;
  right: 0;
  top: 0;
`;
