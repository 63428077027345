import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import {
  BenefitCalculation,
  BenefitDefinitionUserRead,
  BikeBenefitOrderRead,
  SwedenSuggestedBenefitValuesCalculation,
} from "@vapaus/api-codegen";
import { getOrderTotal } from "@vapaus/common-v2";
import { Checkbox } from "@vapaus/form";
import { Button, HorizontalLine, TextButton, Typography } from "@vapaus/ui-v2";

import { AcceptOrderFormSchema } from "../hooks/useAcceptOrderForm";
import { ContractSummaryCard } from "./ContractSummaryCard/ContractSummaryCard";

interface LeasingContractConfirmCardProps {
  order: BikeBenefitOrderRead;
  calculation?: BenefitCalculation;
  benefitDefinition?: BenefitDefinitionUserRead;
  leasingCost?: number;
  onModalOpen: () => void;
  isLoading: boolean;
  swedenSuggestedBenefitValues?: SwedenSuggestedBenefitValuesCalculation;
}

export function LeasingContractConfirmCard({
  order,
  calculation,
  benefitDefinition,
  leasingCost,
  onModalOpen,
  isLoading,
  swedenSuggestedBenefitValues,
}: LeasingContractConfirmCardProps) {
  const { t } = useTranslation();
  const { watch } = useFormContext<AcceptOrderFormSchema>();
  const watchGuidelineAccepted = watch("guidelineAccepted", false);
  const benefitDefinitionTerms =
    benefitDefinition?.currentBenefitDefinitionTerm;

  return (
    <ContractSummaryCard
      benefitDefinition={benefitDefinition}
      redemptionPrice={calculation?.residualValue}
      residualRatio={calculation?.redemptionPercentage}
      initialMonthlyTaxableValue={
        order.fixedMonthlyTaxableValue ?? calculation?.monthlyTaxableValue
      }
      monthlyTaxableValue={
        order.fixedMonthlyTaxableValue ?? calculation?.monthlyTaxableValue
      }
      leasingPeriodMonths={order.leasingPeriodMonths}
      monthlyMaintenanceBudget={order.monthlyMaintenanceBudget}
      downPaymentAmount={order.fixedDownPaymentAmount}
      bikeOrderPrice={getOrderTotal(order)}
      leasingCost={leasingCost}
      swedenSuggestedBenefitValues={swedenSuggestedBenefitValues}
    >
      <>
        <HorizontalLine marginBottom="none" marginTop="none" />
        <Typography>
          {t("userV2:myBike.contractSummaryCard.optionalDescription")}
        </Typography>
        <Checkbox
          name="guidelineAccepted"
          label={
            <Typography>
              <Trans
                i18nKey="userV2:myBike.contractSummaryCard.guidelineAcceptedLabel"
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                components={{
                  a: benefitDefinitionTerms?.content ? (
                    <TextButton
                      size="md"
                      onClick={onModalOpen}
                      children={null}
                    />
                  ) : (
                    <TextButton
                      size="md"
                      to={benefitDefinitionTerms?.pdfObject?.publicUrl ?? ""}
                      target="_blank"
                      children={null}
                    />
                  ),
                }}
              />
            </Typography>
          }
        />
        <div>
          <Button
            disabled={!watchGuidelineAccepted}
            type="submit"
            isLoading={isLoading}
          >
            {t("userV2:myBike.contractSummaryCard.confirmOrder")}
          </Button>
        </div>
      </>
    </ContractSummaryCard>
  );
}
