import { ComponentType } from "react";

import { Flex, SvgIconProps, Typography } from "@vapaus/ui-v2";

type WhyVapausOfferItemProps = {
  title: string;
  description: string;
  icon: ComponentType<SvgIconProps>;
};

export function WhyVapausOfferItem({
  title,
  description,
  icon: Icon,
}: WhyVapausOfferItemProps) {
  return (
    <Flex align="flex-start">
      <Icon size="lg" />
      <Flex direction="column" gap="xxs">
        <Typography weight="bold">{title}</Typography>
        <Typography>{description}</Typography>
      </Flex>
    </Flex>
  );
}
