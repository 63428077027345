import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "@vapaus/ui-v2";

export function InvoicesButtonWithScrollDown() {
  const { t } = useTranslation();
  return (
    <Link to="billing#focus">
      <Button size="small" xsFullWidth>
        {t("userV2:myBike.hero.openInvoiceButton")}
      </Button>
    </Link>
  );
}
