import { UserShopRead } from "@vapaus/api-codegen";
import { Drawer } from "@vapaus/ui-v2";

import { ShopDetails } from "./components/ShopDetails";

type ShopDetailDrawerProps = {
  shop?: UserShopRead;
  onClose: () => void;
};

export function ShopDetailsDrawer({ shop, onClose }: ShopDetailDrawerProps) {
  return (
    <Drawer isOpen={!!shop} onClose={onClose}>
      {shop ? <ShopDetails shop={shop} /> : null}
    </Drawer>
  );
}
