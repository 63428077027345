import { useTranslation } from "react-i18next";

import { BikeBenefitContractReturnOrRedemptionReason } from "@vapaus/api-codegen";
import { useEnumEntries } from "@vapaus/shared-api";
import { Button, Flex, Paper, Radio } from "@vapaus/ui-v2";

import { WizardPageViewLayout } from "../../../../WizardPageViewLayout";
import { useRedemptionProcessContext } from "../../../context/RedemptionProcessContext";

export function RedemptionReasonView() {
  const { t } = useTranslation();
  const { onNext, redemptionReason, setRedemptionReason } =
    useRedemptionProcessContext();
  const { entries: redemptionReasons } = useEnumEntries(
    "BikeBenefitContractReturnOrRedemptionReason",
  );
  const redemptionReasonsFiltered = redemptionReasons.filter(
    (reason) =>
      reason.value !== BikeBenefitContractReturnOrRedemptionReason.EndOfLease,
  );

  return (
    <WizardPageViewLayout
      title={t("userV2:redemptionProcess.redemptionReason.title")}
      description={t("userV2:redemptionProcess.redemptionReason.description")}
    >
      <Paper shadow="md">
        <Flex direction="column" gap="xl">
          <Flex direction="column" gap="lg">
            {redemptionReasonsFiltered.map((reason) => (
              <Radio
                key={reason.label}
                label={reason.label}
                name="reason"
                onChange={() =>
                  setRedemptionReason(
                    reason.value as BikeBenefitContractReturnOrRedemptionReason,
                  )
                }
                value={reason.value}
                defaultChecked={redemptionReason === reason.value}
              />
            ))}
          </Flex>
          <Button onClick={onNext} disabled={!redemptionReason} fullWidth>
            {t("commonV2:next")}
          </Button>
        </Flex>
      </Paper>
    </WizardPageViewLayout>
  );
}
