/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { WorkTypes } from "./WorkTypes";
import {
  WorkTypesFromJSON,
  WorkTypesFromJSONTyped,
  WorkTypesToJSON,
} from "./WorkTypes";

/**
 *
 * @export
 * @interface MaintenanceEventRead
 */
export interface MaintenanceEventRead {
  /**
   *
   * @type {number}
   * @memberof MaintenanceEventRead
   */
  partsPrice: number;
  /**
   *
   * @type {number}
   * @memberof MaintenanceEventRead
   */
  workPrice: number;
  /**
   *
   * @type {WorkTypes}
   * @memberof MaintenanceEventRead
   */
  workType: WorkTypes;
  /**
   *
   * @type {string}
   * @memberof MaintenanceEventRead
   */
  workDescription: string;
  /**
   *
   * @type {Date}
   * @memberof MaintenanceEventRead
   */
  workDate: Date;
  /**
   *
   * @type {string}
   * @memberof MaintenanceEventRead
   */
  id: string;
  /**
   *
   * @type {Currency}
   * @memberof MaintenanceEventRead
   */
  currency: Currency;
  /**
   *
   * @type {Date}
   * @memberof MaintenanceEventRead
   */
  createdAt?: Date;
  /**
   *
   * @type {string}
   * @memberof MaintenanceEventRead
   */
  bikeId?: string;
  /**
   *
   * @type {string}
   * @memberof MaintenanceEventRead
   */
  bikeBenefitContractId?: string;
  /**
   *
   * @type {string}
   * @memberof MaintenanceEventRead
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof MaintenanceEventRead
   */
  vapausCode?: string;
  /**
   *
   * @type {number}
   * @memberof MaintenanceEventRead
   */
  totalPrice: number;
  /**
   *
   * @type {boolean}
   * @memberof MaintenanceEventRead
   */
  isBillable: boolean;
  /**
   *
   * @type {number}
   * @memberof MaintenanceEventRead
   */
  partsVatRate: number;
  /**
   *
   * @type {number}
   * @memberof MaintenanceEventRead
   */
  workVatRate: number;
}

/**
 * Check if a given object implements the MaintenanceEventRead interface.
 */
export function instanceOfMaintenanceEventRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "partsPrice" in value;
  isInstance = isInstance && "workPrice" in value;
  isInstance = isInstance && "workType" in value;
  isInstance = isInstance && "workDescription" in value;
  isInstance = isInstance && "workDate" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "shopId" in value;
  isInstance = isInstance && "totalPrice" in value;
  isInstance = isInstance && "isBillable" in value;
  isInstance = isInstance && "partsVatRate" in value;
  isInstance = isInstance && "workVatRate" in value;

  return isInstance;
}

export function MaintenanceEventReadFromJSON(json: any): MaintenanceEventRead {
  return MaintenanceEventReadFromJSONTyped(json, false);
}

export function MaintenanceEventReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MaintenanceEventRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    partsPrice: json["parts_price"],
    workPrice: json["work_price"],
    workType: WorkTypesFromJSON(json["work_type"]),
    workDescription: json["work_description"],
    workDate: new Date(json["work_date"]),
    id: json["id"],
    currency: CurrencyFromJSON(json["currency"]),
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    bikeId: !exists(json, "bike_id") ? undefined : json["bike_id"],
    bikeBenefitContractId: !exists(json, "bike_benefit_contract_id")
      ? undefined
      : json["bike_benefit_contract_id"],
    shopId: json["shop_id"],
    vapausCode: !exists(json, "vapaus_code") ? undefined : json["vapaus_code"],
    totalPrice: json["total_price"],
    isBillable: json["is_billable"],
    partsVatRate: json["parts_vat_rate"],
    workVatRate: json["work_vat_rate"],
  };
}

export function MaintenanceEventReadToJSON(
  value?: MaintenanceEventRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    parts_price: value.partsPrice,
    work_price: value.workPrice,
    work_type: WorkTypesToJSON(value.workType),
    work_description: value.workDescription,
    work_date: value.workDate.toISOString().substring(0, 10),
    id: value.id,
    currency: CurrencyToJSON(value.currency),
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    bike_id: value.bikeId,
    bike_benefit_contract_id: value.bikeBenefitContractId,
    shop_id: value.shopId,
    vapaus_code: value.vapausCode,
    total_price: value.totalPrice,
    is_billable: value.isBillable,
    parts_vat_rate: value.partsVatRate,
    work_vat_rate: value.workVatRate,
  };
}
