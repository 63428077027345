import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Currency } from "@vapaus/generated";
import { useCurrencyFormat, usePercentFormat } from "@vapaus/i18n";
import { Paper, Typography } from "@vapaus/ui-v2";

import { BenefitCalculationWithExtra } from "../types";

const Grid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing.xs};
  grid-template-columns: 2fr 1fr;
`;

const ResultPaper = styled(Paper)`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
`;

interface ResultBoxProps {
  calculation?: BenefitCalculationWithExtra;
}

export function ResultBox({ calculation }: ResultBoxProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(Currency.EUR);
  const formatPercent = usePercentFormat({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return (
    <Paper shadow="md">
      <ResultPaper background="secondary1.lighter" marginBottom="md">
        <Typography align="center" marginBottom="sm">
          {calculation?.costAfterSavings ? (
            <>
              {t("calculator:resultBox.costAfterSaving")}{" "}
              <b>{formatCurrency(calculation.costAfterSavings)}</b>*
            </>
          ) : (
            "-"
          )}
        </Typography>
        <Typography variant="h2" align="center">
          {calculation?.savings
            ? calculation.savings > 0
              ? t("calculator:resultBox.savings", {
                  value: calculation.savings,
                  formatParams: {
                    value: { currency: "EUR" },
                  },
                })
              : t("calculator:resultBox.noSavings")
            : "-"}
        </Typography>
      </ResultPaper>
      <Grid>
        <Typography weight="bold">
          {t("calculator:resultBox.durationLabel")}
        </Typography>
        <Typography weight="bold" align="right">
          {t("calculator:resultBox.durationValue", {
            value: calculation?.leasingPeriodMonths ?? "-",
          })}
        </Typography>
        <Typography weight="bold">
          {t("calculator:resultBox.salaryDeduction")}
        </Typography>
        <Typography weight="bold" align="right">
          {formatCurrency(calculation?.totalValueAmount)}
        </Typography>
        <Typography weight="bold">
          {t("calculator:resultBox.incomeTaxSaving")}
        </Typography>
        <Typography weight="bold" align="right">
          {formatCurrency(calculation?.taxAmountReduction)}
        </Typography>
        <Typography weight="bold">
          {t("calculator:resultBox.redemptionValue", {
            redemptionPercentage: formatPercent(
              calculation?.redemptionPercentage,
            ),
          })}
        </Typography>
        <Typography weight="bold" align="right">
          {formatCurrency(calculation?.residualValue)}
        </Typography>
      </Grid>
    </Paper>
  );
}
