import * as React from "react";

import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { useCurrencyFormat, useDateFormat } from "@vapaus/i18n";
import { useEnumEntries } from "@vapaus/shared-api";
import {
  Button,
  DatePicker,
  Flex,
  Input,
  RowItem,
  Spinner,
} from "@vapaus/ui-v2";

import { WizardPageViewLayout } from "../../../WizardPageViewLayout";
import { useReturnProcessContext } from "../../context/ReturnProcessContext";
import { PickupAddressDetails } from "../../machines/types";
import { CityAndPostalCodeSection } from "./components/CityAndPostalCodeSection";
import { useDeliveryFee } from "./hooks/useDeliveryFee";

export type PickupAddressDetailsForm =
  | Omit<PickupAddressDetails, "country" | "requestedPickupDate">
  | { country: string; requestedPickupDate: string };

export function ChoosePickupDetailsView() {
  const { t } = useTranslation();
  const { getEnumLabel: getCountryLabel } = useEnumEntries(
    "SupportedCountriesEnum",
  );

  const {
    onNext,
    pickupAddressDetails,
    setPickupAddressDetails,
    preliminaryData,
    currency,
    isContractEndingSoon,
  } = useReturnProcessContext();
  const formatDate = useDateFormat();
  const formatCurrency = useCurrencyFormat(currency);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    setValue,
  } = useForm<PickupAddressDetailsForm>({
    defaultValues: {
      ...pickupAddressDetails,
      country: getCountryLabel(pickupAddressDetails.country),
      requestedPickupDate: pickupAddressDetails.requestedPickupDate
        ?.toISOString()
        .substring(0, 10),
    },
  });

  const { deliveryFee, isFetching } = useDeliveryFee();

  const minDate = isContractEndingSoon
    ? DateTime.now().toFormat("yyyy-MM-dd")
    : DateTime.now().plus({ days: 7 }).toFormat("yyyy-MM-dd");

  if (!preliminaryData) {
    return null;
  }

  return (
    <WizardPageViewLayout
      title={t("userV2:returnProcess.choosePickupDetails.title")}
      description={
        <Trans
          i18nKey="userV2:returnProcess.choosePickupDetails.description"
          values={{ date: formatDate(preliminaryData.leaseEndDate) }}
          components={{ b: <b /> }}
        />
      }
    >
      <StyledFormContainer>
        <form onSubmit={handleSubmit(onNext)}>
          <Flex direction="column" gap="md" align="center" fullWidth>
            <Flex direction="column" align="center" fullWidth>
              <Input
                fullWidth
                placeholder={t(
                  "userV2:returnProcess.choosePickupDetails.address.placeholder",
                )}
                label={t(
                  "userV2:returnProcess.choosePickupDetails.address.label",
                )}
                {...register("address", {
                  required: true,
                  onChange: (e) =>
                    setPickupAddressDetails({ address: e.target.value }),
                })}
              />
              <CityAndPostalCodeSection
                register={register}
                setValue={setValue}
              />
              <Input
                fullWidth
                label={t("userV2:returnProcess.choosePickupDetails.country")}
                {...register("country", {
                  required: true,
                })}
                disabled
              />
              <Input
                fullWidth
                placeholder={t(
                  "userV2:returnProcess.choosePickupDetails.phoneNumber.placeholder",
                )}
                label={t(
                  "userV2:returnProcess.choosePickupDetails.phoneNumber.label",
                )}
                {...register("phoneNumber", {
                  required: true,
                  onChange: (e) =>
                    setPickupAddressDetails({ phoneNumber: e.target.value }),
                })}
              />
              <DatePicker
                {...register("requestedPickupDate", {
                  required: true,
                  valueAsDate: true,
                  onChange: (e) =>
                    setPickupAddressDetails({
                      requestedPickupDate: new Date(e.target.value),
                    }),
                })}
                label={t(
                  "userV2:returnProcess.choosePickupDetails.requestedPickupDate",
                )}
                noManualEntry
                min={minDate}
                max={DateTime.fromJSDate(preliminaryData.leaseEndDate).toFormat(
                  "yyyy-MM-dd",
                )}
              />
            </Flex>
            <RowItemContainer>
              <RowItem
                label={t(
                  "userV2:returnProcess.choosePickupDetails.pickupPrice",
                )}
                value={isFetching ? <Spinner /> : formatCurrency(deliveryFee)}
                labelIsBold
                valueIsBold
              />
            </RowItemContainer>
            <Button
              type="submit"
              fullWidth
              disabled={!isValid || !pickupAddressDetails.city}
            >
              {t("commonV2:next")}
            </Button>
          </Flex>
        </form>
      </StyledFormContainer>
    </WizardPageViewLayout>
  );
}

const StyledFormContainer = styled.div`
  width: 24rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      max-width: 24rem;
      width: 100%;
    `)}
`;

const RowItemContainer = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacing.xs} 0;
`;
