/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface SwedenSuggestedBenefitValuesCalculation
 */
export interface SwedenSuggestedBenefitValuesCalculation {
  /**
   *
   * @type {number}
   * @memberof SwedenSuggestedBenefitValuesCalculation
   */
  suggestedSalaryDeduction: number;
  /**
   *
   * @type {number}
   * @memberof SwedenSuggestedBenefitValuesCalculation
   */
  suggestedEmployerShare: number;
  /**
   *
   * @type {number}
   * @memberof SwedenSuggestedBenefitValuesCalculation
   */
  monthlyBenefitValueAfterTaxDeduction: number;
}

/**
 * Check if a given object implements the SwedenSuggestedBenefitValuesCalculation interface.
 */
export function instanceOfSwedenSuggestedBenefitValuesCalculation(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "suggestedSalaryDeduction" in value;
  isInstance = isInstance && "suggestedEmployerShare" in value;
  isInstance = isInstance && "monthlyBenefitValueAfterTaxDeduction" in value;

  return isInstance;
}

export function SwedenSuggestedBenefitValuesCalculationFromJSON(
  json: any,
): SwedenSuggestedBenefitValuesCalculation {
  return SwedenSuggestedBenefitValuesCalculationFromJSONTyped(json, false);
}

export function SwedenSuggestedBenefitValuesCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SwedenSuggestedBenefitValuesCalculation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    suggestedSalaryDeduction: json["suggested_salary_deduction"],
    suggestedEmployerShare: json["suggested_employer_share"],
    monthlyBenefitValueAfterTaxDeduction:
      json["monthly_benefit_value_after_tax_deduction"],
  };
}

export function SwedenSuggestedBenefitValuesCalculationToJSON(
  value?: SwedenSuggestedBenefitValuesCalculation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    suggested_salary_deduction: value.suggestedSalaryDeduction,
    suggested_employer_share: value.suggestedEmployerShare,
    monthly_benefit_value_after_tax_deduction:
      value.monthlyBenefitValueAfterTaxDeduction,
  };
}
