import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import {
  ConstantsApi,
  Enum,
  EnumEntry,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";

import { UseQueryOptions } from "../types";

type Enums = {
  [key: string]: EnumEntry[];
};

// Keep this updated from backend_next/app/api/endpoints/enum.py -> enums
type EnumIdentifier =
  | "SupportedCountriesEnum"
  | "SupportedLanguagesEnum"
  | "Currency"
  | "BikeBenefitOrderState"
  | "AdditionalServiceType"
  | "BikeDriveType"
  | "BikeFrameType"
  | "SaleInvoiceLineType"
  | "SaleInvoiceStatus"
  | "BenefitDefinitionAccessRequestStatus"
  | "BenefitCancellationCoverageStatus"
  | "AccessoryType"
  | "WorkTypes"
  | "BikeBenefitOrderingMethod"
  | "Plan"
  | "BenefitDefinitionAccessRulesRequestRequirements"
  | "BikeBenefitContractReturnOrRedemptionReason"
  | "ShopType";

const constantsApi = new ConstantsApi(apiConfiguration);

export const useEnum = (
  options?: Omit<
    UseQueryOptions<Enum[], ResponseError, Enums>,
    "queryKey" | "queryFn"
  >,
) => {
  const { i18n } = useTranslation();
  return useQuery<Enum[], ResponseError, Enums>(
    ["enum", i18n.language],
    () => constantsApi.constantsGetEnums(),
    {
      staleTime: 60 * 60 * 24, // 24 hours
      select: (enums) =>
        enums.reduce((acc: Enums, v) => {
          acc[v.identifier] = v.entries;
          return acc;
        }, {}),
      ...options,
    },
  );
};

export const useEnumEntries = (enumIdentifier: EnumIdentifier) => {
  const { data } = useEnum();
  const entries = data?.[enumIdentifier] ?? [];
  const getEnumLabel = (value?: string) =>
    entries.find((e) => e.value === value)?.label ?? value; // If the label is not found, return the value as fallback
  return { getEnumLabel, entries };
};
