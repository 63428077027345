import { Trans, useTranslation } from "react-i18next";

import { useCurrencyFormat } from "@vapaus/i18n";
import { Button, Flex, HorizontalLine, Paper, RowItem } from "@vapaus/ui-v2";

import { WizardPageViewLayout } from "../../../WizardPageViewLayout";
import { useReturnProcessContext } from "../../context/ReturnProcessContext";
import { Accessory } from "./ component/Accessory";
import { useReturnProcessAccessories } from "./hooks/useReturnProcessAccessories";

export function AccessoriesView() {
  const { t } = useTranslation();
  const { onNext, currency } = useReturnProcessContext();
  const { accessories, selectedAccessoriesTotalPrice } =
    useReturnProcessAccessories();
  const formatCurrency = useCurrencyFormat(currency);

  return (
    <WizardPageViewLayout
      title={t("userV2:returnProcess.accessories.title")}
      description={
        <Trans
          i18nKey="userV2:returnProcess.accessories.description"
          components={{ b: <b /> }}
        />
      }
    >
      <Paper shadow="md">
        <Flex direction="column" gap="lg" data-testid="accessories-list">
          {accessories.map((accessory) => (
            <Accessory key={accessory.id} accessory={accessory} />
          ))}
          <HorizontalLine marginTop="none" marginBottom="none" />
          <RowItem
            label={t("userV2:returnProcess.accessories.total")}
            labelIsBold
            value={formatCurrency(selectedAccessoriesTotalPrice)}
            valueIsBold
          />
          <Button onClick={onNext} fullWidth>
            {t("commonV2:next")}
          </Button>
        </Flex>
      </Paper>
    </WizardPageViewLayout>
  );
}
