import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { PageRoot, useUserCountry } from "@vapaus/common-v2";
import { Button, Card, Flex, Typography } from "@vapaus/ui-v2";

export function SupportPage() {
  const { t } = useTranslation();
  const { isSwedishUser } = useUserCountry();

  return (
    <PageRoot>
      <Flex direction="column" gap="lg">
        <Flex direction="column" gap="sm">
          <Typography variant="h1" xsAlign="center">
            {t("userV2:supportPage.support")}
          </Typography>
          <Typography variant="bodyLg" xsAlign="center">
            {t("userV2:supportPage.description")}
          </Typography>
        </Flex>
        {isSwedishUser ? (
          <Flex smDirection="column">
            <StyledLink
              href={t("userV2:supportPage.sweden.faqLink")}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="primary1" smFullWidth>
                {t("userV2:supportPage.sweden.faq")}
              </Button>
            </StyledLink>
            <StyledLink
              href={t("userV2:supportPage.sweden.contactUsLink")}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="outlined" smFullWidth>
                {t("userV2:supportPage.sweden.contactUs")}
              </Button>
            </StyledLink>
          </Flex>
        ) : (
          <Card
            title={t("userV2:supportPage.knowledgeBase")}
            subtitle={t("userV2:supportPage.knowledgeBaseDescription")}
          >
            <Flex smDirection="column">
              <StyledLink
                href={t("userV2:supportPage.knowledgeBaseLink")}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="primary1" smFullWidth>
                  {t("userV2:supportPage.knowledgeBase")}
                </Button>
              </StyledLink>
              <StyledLink
                href={t("userV2:supportPage.contactLink")}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="outlined" smFullWidth>
                  {t("userV2:supportPage.contactUs")}
                </Button>
              </StyledLink>
            </Flex>
          </Card>
        )}
      </Flex>
    </PageRoot>
  );
}

const StyledLink = styled.a`
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      width: 100%;
    `)};
`;
