import { Trans, useTranslation } from "react-i18next";

import {
  BenefitCalculation,
  BikeBenefitOrderRead,
  SupportedCountriesEnum,
} from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import { Card, Flex, TextButton } from "@vapaus/ui-v2";

import { NameValueDescription } from "../../../components/NameValueDescription";

interface LeasingCostsCardProps {
  order: BikeBenefitOrderRead;
  calculation?: BenefitCalculation;
}

export function LeasingCostsCard({
  order,
  calculation,
}: LeasingCostsCardProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(order.currency);

  return (
    <Card title={t("userV2:myBike.leasingCostsCard.title")}>
      <Flex direction="column" gap="md">
        <NameValueDescription
          name={t("userV2:myBike.leasingCostsCard.item1.name")}
          value={formatCurrency(
            calculation
              ? calculation.monthlyInsuranceFee *
                  calculation.leasingPeriodMonths
              : null,
          )}
          description={
            calculation && calculation.downPaymentAmount > 0
              ? t("userV2:myBike.leasingCostsCard.item1.descriptionAlternate")
              : t("userV2:myBike.leasingCostsCard.item1.description")
          }
        />
        <NameValueDescription
          name={t("userV2:myBike.leasingCostsCard.item2.name")}
          value={formatCurrency(calculation?.totalFinancingCost)}
          description={t("userV2:myBike.leasingCostsCard.item2.description")}
        />
        <NameValueDescription
          name={t("userV2:myBike.leasingCostsCard.item3.name")}
          value={formatCurrency(calculation?.totalServiceCost)}
          description={
            <Trans
              i18nKey="userV2:myBike.leasingCostsCard.item3.description"
              components={{
                1: (
                  <TextButton
                    to={
                      order.user?.country === SupportedCountriesEnum.Se
                        ? t("userV2:myBike.leasingCostsCard.sweden.item3.link")
                        : t("userV2:myBike.leasingCostsCard.item3.link")
                    }
                    target="_blank"
                    rel="noreferrer"
                    size="md"
                  >
                    Learn more
                  </TextButton>
                ),
              }}
            />
          }
        />
      </Flex>
    </Card>
  );
}
