import { ReactNode } from "react";

import styled, { css } from "styled-components";

import { Button } from "../Button";
import { CircularButton } from "../CircularButton";
import { Flex } from "../Flex";
import { CloseIcon } from "../Icons";
import { defaultTheme } from "../styles";

export type BannerVariant = "success" | "error" | "warning" | "neutral";

interface BannerStateDescription {
  background: string;
}

type VariantsDescription = {
  [key in BannerVariant]: BannerStateDescription;
};

const variants: VariantsDescription = {
  success: {
    background: defaultTheme.palette.secondary1.lighter,
  },
  error: {
    background: defaultTheme.palette.secondary2.lighter,
  },
  warning: {
    background: defaultTheme.palette.secondary3.lighter,
  },
  neutral: {
    background: defaultTheme.palette.neutral.lighter,
  },
};

function makeBannerColour({
  variant = "neutral",
}: {
  variant?: BannerVariant;
}) {
  const variantDesc = variants[variant];

  return css`
    background-color: ${variantDesc.background};
  `;
}

type BannerProps = {
  variant: BannerVariant;
  children: ReactNode;
  onButtonClick?: () => void;
  buttonText?: string;
  onClose?: () => void;
};

export function Banner({
  variant,
  children,
  onButtonClick,
  buttonText,
  onClose,
}: BannerProps) {
  return (
    <StyledRoot variant={variant} role="status" smAlign="flex-start">
      <Flex smDirection="column" xsAlign="stretch">
        <StyledContent>{children}</StyledContent>
        {onButtonClick ? (
          <Button variant="outlined" onClick={onButtonClick} size="small">
            {buttonText}
          </Button>
        ) : null}
      </Flex>

      {onClose ? (
        <CircularButton onClick={onClose} icon={<CloseIcon />} size="sm" />
      ) : null}
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)<{ variant: BannerVariant }>`
  width: 100%;
  border-bottom: ${({ theme }) => theme.border.secondary.main};
  padding: ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.lg};
  ${makeBannerColour}
`;

const StyledContent = styled.div`
  flex: 1;
`;
