import { Trans, useTranslation } from "react-i18next";

import { BikeBenefitContractReturnOrRedemptionReason } from "@vapaus/api-codegen";
import { useCurrencyFormat, useDateFormat } from "@vapaus/i18n";

import { useReturnProcessContext } from "../../../context/ReturnProcessContext";

export function useShippingFees() {
  const { t } = useTranslation();
  const { preliminaryData, returnReason, currency } = useReturnProcessContext();
  const formatCurrency = useCurrencyFormat(currency);
  const formatDate = useDateFormat();

  const isShippingFree =
    returnReason ===
      BikeBenefitContractReturnOrRedemptionReason.EmploymentTerminatedByEmployer ||
    (preliminaryData?.minShippingFee === 0 &&
      preliminaryData?.maxShippingFee === 0);
  const isSingleNonZeroShippingFee =
    preliminaryData?.minShippingFee === preliminaryData?.maxShippingFee &&
    preliminaryData?.minShippingFee !== 0;

  const description = isShippingFree ? (
    <Trans
      i18nKey="userV2:returnProcess.chooseReturnMethod.description.freeShipping"
      values={{ date: formatDate(preliminaryData?.leaseEndDate) }}
      components={{ b: <b /> }}
    />
  ) : isSingleNonZeroShippingFee ? (
    <Trans
      i18nKey="userV2:returnProcess.chooseReturnMethod.description.singleNonZeroShippingFee"
      values={{
        date: formatDate(preliminaryData?.leaseEndDate),
        fee: formatCurrency(preliminaryData?.minShippingFee),
      }}
      components={{ b: <b /> }}
    />
  ) : (
    <Trans
      i18nKey="userV2:returnProcess.chooseReturnMethod.description.shippingFeesRange"
      values={{
        date: formatDate(preliminaryData?.leaseEndDate),
        range: `${formatCurrency(
          preliminaryData?.minShippingFee,
        )}-${formatCurrency(preliminaryData?.maxShippingFee)}`,
      }}
      components={{ b: <b /> }}
    />
  );

  const pickupOptionText = isShippingFree
    ? t("userV2:returnProcess.chooseReturnMethod.pickupButton.freeShipping")
    : isSingleNonZeroShippingFee
    ? `${formatCurrency(preliminaryData?.minShippingFee)}`
    : `${formatCurrency(preliminaryData?.minShippingFee)}-${formatCurrency(
        preliminaryData?.maxShippingFee,
      )}`;

  return {
    description,
    pickupOptionText,
  };
}
