import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";

import { BenefitDefinitionUserRead } from "@vapaus/api-codegen";
import {
  Card,
  Flex,
  HorizontalLine,
  LineItem,
  RowItem,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { useGetCurrentUser } from "../../../api/user";

export function EmploymentDetailsTabContent() {
  const { t } = useTranslation();
  const { benefitDefinition, businessId } = useOutletContext<{
    benefitDefinition?: BenefitDefinitionUserRead;
    businessId?: string;
  }>();
  const { data: user } = useGetCurrentUser();

  if (!user) {
    return null;
  }

  return (
    <StyledRoot>
      <Flex direction="column" gap="lg">
        <Card title={t("userV2:benefit.tabs.employmentDetails.title")}>
          <Flex direction="column">
            <RowItem
              label={t("userV2:benefit.tabs.employmentDetails.employerName")}
              value={benefitDefinition?.organisation?.name ?? "-"}
            />
            <RowItem
              label={t(
                "userV2:benefit.tabs.employmentDetails.employerBusinessId",
              )}
              value={benefitDefinition?.organisation?.businessId ?? businessId}
            />
            <RowItem
              label={t("userV2:benefit.tabs.employmentDetails.employeeName")}
              value={user.fullName}
            />
            <RowItem
              label={t("userV2:benefit.tabs.employmentDetails.emailAddress")}
              value={user.email}
            />
            <HorizontalLine marginTop="sm" marginBottom="sm" />
            <LineItem
              name={t("userV2:benefit.tabs.employmentDetails.employeeId")}
              content={
                user.employeeNumber ??
                t("userV2:benefit.tabs.employmentDetails.none")
              }
            />
            <HorizontalLine marginTop="sm" marginBottom="sm" />
            <LineItem
              name={t("userV2:benefit.tabs.employmentDetails.costCenter")}
              content={
                user.costCenter ??
                t("userV2:benefit.tabs.employmentDetails.none")
              }
            />
          </Flex>
        </Card>
        <Flex direction="column" gap="xs" align="center">
          <Typography>
            {t("userV2:benefit.tabs.employmentDetails.changesInEmployment")}
          </Typography>
          <TextButton
            size="md"
            to={t("userV2:benefit.tabs.employmentDetails.faqLink")}
            target="_blank"
          >
            {t("userV2:benefit.tabs.employmentDetails.learnMoreInFaq")}
          </TextButton>
        </Flex>
      </Flex>
    </StyledRoot>
  );
}

const StyledRoot = styled.div`
  margin: ${({ theme }) => theme.spacing.xl} 0;
`;
