import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";

import {
  BenefitCalculation,
  BenefitDefinitionUserRead,
  BikeBenefitContractRead,
  BikeBenefitOrderRead,
  CalculationsApi,
  Plan,
  ResponseError,
  SupportedOrganisationCountries,
  type SwedenSuggestedBenefitValuesCalculation,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";

import { UseQueryOptions } from "../types";

const calculationsApi = new CalculationsApi(apiConfiguration);

export type OrderCalculationParams = {
  plan?: Plan;
  benefitDefinitionId: string;
  monthlyMaintenanceBudget: number;
  leasingPeriodMonths?: number;
  totalPackagePrice: number;
  fixedDownPaymentAmount?: number;
  hasFixedTaxableValue?: boolean;
  fixedMonthlyTaxableValue?: number;
  contractCorrection?: {
    correctedContractId: string;
  };
  contractRevision?: {
    revisedContractId: string;
    revisionApplyDate: string | Date;
  };
};

export const useOrderCalculation = (
  params?: OrderCalculationParams,
  options?: UseQueryOptions<BenefitCalculation, ResponseError>,
) => {
  return useQuery<BenefitCalculation, ResponseError>({
    enabled: Boolean(params),
    queryKey: ["calculation", params],
    queryFn: () => {
      invariant(params);
      const contractRevision = params.contractRevision
        ? {
            revisedContractId: params.contractRevision.revisedContractId,
            revisionApplyDate: new Date(
              params.contractRevision.revisionApplyDate,
            ),
          }
        : undefined;

      if (params.plan === Plan.Custom || params.plan === Plan.Legacy) {
        if (params.leasingPeriodMonths && !params.hasFixedTaxableValue) {
          return calculationsApi.calculationsGetFixedPeriodCalculation({
            bodyCalculationsGetFixedPeriodCalculation: {
              benefitDefinitionId: params.benefitDefinitionId,
              monthlyMaintenanceBudget: params.monthlyMaintenanceBudget,
              leasingPeriodMonths: params.leasingPeriodMonths,
              fixedDownPaymentAmount: params.fixedDownPaymentAmount,
              totalPackagePrice: params.totalPackagePrice,
              contractCorrection: params.contractCorrection,
              contractRevision,
            },
          });
        }

        return calculationsApi.calculationsGetShortestPeriodCalculation({
          bodyCalculationsGetShortestPeriodCalculation: {
            benefitDefinitionId: params.benefitDefinitionId,
            monthlyMaintenanceBudget: params.monthlyMaintenanceBudget,
            fixedDownPaymentAmount: params.fixedDownPaymentAmount,
            totalPackagePrice: params.totalPackagePrice,
            fixedMonthlyTaxableValue: params.fixedMonthlyTaxableValue,
            contractCorrection: params.contractCorrection,
            contractRevision,
          },
        });
      }
      if (params.plan === Plan.SwedenPilot) {
        return calculationsApi.calculationsGetFixedPeriodSwedenPilotCalculation(
          {
            bodyCalculationsGetFixedPeriodSwedenPilotCalculation: {
              benefitDefinitionId: params.benefitDefinitionId,
              leasingPeriodMonths: params.leasingPeriodMonths,
              monthlyMaintenanceBudget: params.monthlyMaintenanceBudget,
              fixedDownPaymentAmount: params.fixedDownPaymentAmount,
              totalPackagePrice: params.totalPackagePrice,
              contractCorrection: params.contractCorrection,
              contractRevision,
            },
          },
        );
      }
      return calculationsApi.calculationsGetFixedMonthlyPaymentCalculation({
        bodyCalculationsGetFixedMonthlyPaymentCalculation: {
          benefitDefinitionId: params.benefitDefinitionId,
          monthlyPayment: params.fixedMonthlyTaxableValue || 0,
          monthlyMaintenanceBudget: params.monthlyMaintenanceBudget,
          fixedDownPaymentAmount: params.fixedDownPaymentAmount,
          totalPackagePrice: params.totalPackagePrice,
          contractCorrection: params.contractCorrection,
          contractRevision,
        },
      });
    },
    ...options,
  });
};

export const useSwedenContractBenefitValuesCalculation = (
  contract: BikeBenefitContractRead,
  options?: UseQueryOptions<SwedenSuggestedBenefitValuesCalculation>,
) =>
  useQuery<SwedenSuggestedBenefitValuesCalculation, ResponseError>(
    ["swedenSuggestedBenefitValuesCalculationForContract", contract],
    () =>
      calculationsApi.calculationsGetSwedenSuggestedBenefitValuesCalculationForContract(
        { bikeBenefitContractId: contract.id },
      ),
    {
      enabled:
        !!contract.id &&
        contract.benefitCountry === SupportedOrganisationCountries.Se,
      ...options,
    },
  );

export const useSwedenOrderBenefitValuesCalculation = (
  order: BikeBenefitOrderRead,
  benefitDefinition?: BenefitDefinitionUserRead,
  options?: UseQueryOptions<SwedenSuggestedBenefitValuesCalculation>,
) =>
  useQuery<SwedenSuggestedBenefitValuesCalculation, ResponseError>(
    ["swedenSuggestedBenefitValuesCalculationForOrder", order],
    () =>
      calculationsApi.calculationsGetSwedenSuggestedBenefitValuesCalculationForOrder(
        { bikeBenefitOrderId: order.id },
      ),
    {
      enabled:
        !!order.id &&
        benefitDefinition &&
        benefitDefinition.organisation.country ===
          SupportedOrganisationCountries.Se,
      ...options,
    },
  );
