import { MouseEventHandler, ReactNode, forwardRef } from "react";

import { get } from "lodash-es";
import styled, { css } from "styled-components";
import invariant from "tiny-invariant";

import { PaletteVariants, Theme, addAlpha, makeTransition } from "../styles";

type ButtonVariants = "default" | "outlined" | "secondary";

type ButtonStates = "inactive" | "hover" | "active";

type ButtonStateDescription = {
  border?: PaletteVariants;
  background: PaletteVariants;
  text: PaletteVariants;
};

type VariantsDescription = {
  [key in ButtonVariants]: {
    [key in ButtonStates]: ButtonStateDescription;
  };
};

const variants: VariantsDescription = {
  default: {
    inactive: {
      background: "common.transparent",
      text: "primary1.main",
    },
    hover: {
      background: "neutral.light",
      text: "primary1.main",
    },
    active: {
      background: "background.white",
      text: "primary1.main",
    },
  },
  outlined: {
    inactive: {
      border: "primary1.main",
      background: "background.white",
      text: "primary1.main",
    },
    hover: {
      border: "common.white",
      background: "primary1.main",
      text: "primary1.main",
    },
    active: {
      background: "background.white",
      text: "primary1.main",
    },
  },
  secondary: {
    inactive: {
      border: "primary1.main",
      background: "primary1.main",
      text: "primary2.main",
    },
    hover: {
      border: "common.white",
      background: "background.white",
      text: "primary1.main",
    },
    active: {
      background: "background.white",
      text: "primary1.main",
    },
  },
};

function makeButtonColor({
  theme,
  variant = "default",
}: {
  theme: Theme;
  variant?: CircularButtonProps["variant"];
}) {
  const variantDesc = variants[variant];

  return css`
    background-color: ${get(theme.palette, variantDesc.inactive.background)};
    color: ${get(theme.palette, variantDesc.inactive.text)};
    outline: ${variantDesc.inactive.border
      ? `${get(theme.palette, variantDesc.inactive.border)} solid 2px`
      : "none"};
    outline-offset: -2px;
    border: none;

    svg {
      fill: ${get(theme.palette, variantDesc.inactive.text)};
    }

    &:hover:enabled {
      background-color: ${addAlpha(
        `${get(theme.palette, variantDesc.hover.background)}`,
        0.05,
      )};
      color: ${get(theme.palette, variantDesc.hover.text)};

      svg {
        fill: ${get(theme.palette, variantDesc.hover.text)};
      }
    }

    &:active:enabled {
      background-color: ${get(theme.palette, variantDesc.active.background)};
      color: ${get(theme.palette, variantDesc.active.text)};
      box-shadow: ${({ theme }) => theme.shadows.inset};

      svg {
        fill: ${get(theme.palette, variantDesc.active.text)};
      }
    }
  `;
}

const ButtonSizes = {
  lg: "48px",
  md: "40px",
  sm: "32px",
};

type BaseButtonProps = {
  variant?: ButtonVariants;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  size?: keyof typeof ButtonSizes;
  ariaLabel?: string;
  className?: string;
};

type IconButtonProps = BaseButtonProps & {
  icon: ReactNode;
  text?: never;
};

type TextButtonProps = BaseButtonProps & {
  icon?: never;
  text: string;
};

export type CircularButtonProps = IconButtonProps | TextButtonProps;

export const CircularButton = forwardRef<
  HTMLButtonElement,
  CircularButtonProps
>(function CircularButton({ icon, text, ariaLabel, ...rest }, ref) {
  invariant(
    !text || (text && text.length === 2),
    'The "text" prop must be exactly 2 characters long',
  );
  return (
    <Root ref={ref} aria-label={ariaLabel} {...rest} type="button">
      {icon ?? text}
    </Root>
  );
});

const Root = styled.button<BaseButtonProps>`
  width: ${({ size = "lg" }) => ButtonSizes[size]};
  height: ${({ size = "lg" }) => ButtonSizes[size]};
  min-width: ${({ size = "lg" }) => ButtonSizes[size]};
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: ${({ theme }) => theme.typography.button.fontSize};
  font-family: ${({ theme }) => theme.typography.button.fontFamily};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  box-sizing: border-box;
  cursor: pointer;

  ${makeButtonColor}
  ${makeTransition("all", "shortest", "easeOut")}
  &:disabled {
    opacity: ${({ theme }) => theme.opacity.disabled};
    cursor: not-allowed;
  }
`;
