import { useTranslation } from "react-i18next";

import {
  BuildIcon,
  Button,
  CardCollapsible,
  CarriageReturnIcon,
  CreditCardIcon,
  DiamondIcon,
  EcoIcon,
  Flex,
  InventoryIcon,
  Paper,
  PedalBikeIcon,
  SupportAgentIcon,
  Typography,
  VerifiedUserIcon,
} from "@vapaus/ui-v2";

import { WhyVapausOfferItem } from "./WhyVapausOfferItem";

type WhyVapausProps = {
  isPublic?: boolean;
};

export function WhyVapaus({ isPublic }: WhyVapausProps) {
  const { t } = useTranslation();
  return (
    <CardCollapsible
      icon={<DiamondIcon />}
      title={t("calculator:whyVapaus.title")}
    >
      <Flex direction="column" gap="md">
        <Typography>{t("calculator:whyVapaus.description")}</Typography>
        <Flex direction="column" gap="lg">
          <Typography weight="bold" variant="bodyLg">
            {t("calculator:whyVapaus.whatDoWeOffer")}
          </Typography>
          <WhyVapausOfferItem
            title={t("calculator:whyVapaus.wideSelectionOfBike.title")}
            description={t(
              "calculator:whyVapaus.wideSelectionOfBike.description",
            )}
            icon={PedalBikeIcon}
          />
          <WhyVapausOfferItem
            title={t("calculator:whyVapaus.stellarExperience.title")}
            description={t(
              "calculator:whyVapaus.stellarExperience.description",
            )}
            icon={InventoryIcon}
          />
          <WhyVapausOfferItem
            title={t("calculator:whyVapaus.comprehensiveInsurance.title")}
            description={t(
              "calculator:whyVapaus.comprehensiveInsurance.description",
            )}
            icon={VerifiedUserIcon}
          />
          <WhyVapausOfferItem
            title={t("calculator:whyVapaus.carbonNeutralCommute.title")}
            description={t(
              "calculator:whyVapaus.carbonNeutralCommute.description",
            )}
            icon={EcoIcon}
          />
          <WhyVapausOfferItem
            title={t("calculator:whyVapaus.exceptionalCustomerService.title")}
            description={t(
              "calculator:whyVapaus.exceptionalCustomerService.description",
            )}
            icon={SupportAgentIcon}
          />
          <WhyVapausOfferItem
            title={t("calculator:whyVapaus.cancellationCoverage.title")}
            description={t(
              "calculator:whyVapaus.cancellationCoverage.description",
            )}
            icon={CarriageReturnIcon}
          />
          <WhyVapausOfferItem
            title={t("calculator:whyVapaus.maintenanceBudget.title")}
            description={t(
              "calculator:whyVapaus.maintenanceBudget.description",
            )}
            icon={BuildIcon}
          />
          <WhyVapausOfferItem
            title={t("calculator:whyVapaus.downPayment.title")}
            description={t("calculator:whyVapaus.downPayment.description")}
            icon={CreditCardIcon}
          />
          {isPublic ? (
            <Paper background="secondary1.lighter">
              <Flex direction="column" align="center">
                <Typography variant="h2" align="center" weight="bold">
                  {t("calculator:whyVapaus.footer.title")}
                </Typography>
                <Typography align="center" marginBottom="xs">
                  {t("calculator:whyVapaus.footer.description")}
                </Typography>
                <a
                  href={t("calculator:whyVapaus.footer.link")!}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button xsFullWidth>
                    {t("calculator:whyVapaus.footer.button")}
                  </Button>
                </a>
              </Flex>
            </Paper>
          ) : null}
        </Flex>
      </Flex>
    </CardCollapsible>
  );
}
