/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { AnnouncementVariant } from "./AnnouncementVariant";
import {
  AnnouncementVariantFromJSON,
  AnnouncementVariantFromJSONTyped,
  AnnouncementVariantToJSON,
} from "./AnnouncementVariant";
import type { AvailableScopes } from "./AvailableScopes";
import {
  AvailableScopesFromJSON,
  AvailableScopesFromJSONTyped,
  AvailableScopesToJSON,
} from "./AvailableScopes";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
} from "./SupportedCountriesEnum";

/**
 *
 * @export
 * @interface AnnouncementWrite
 */
export interface AnnouncementWrite {
  /**
   *
   * @type {string}
   * @memberof AnnouncementWrite
   */
  internalName: string;
  /**
   *
   * @type {AnnouncementVariant}
   * @memberof AnnouncementWrite
   */
  variant: AnnouncementVariant;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof AnnouncementWrite
   */
  content: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof AnnouncementWrite
   */
  isEnabled?: boolean;
  /**
   *
   * @type {Date}
   * @memberof AnnouncementWrite
   */
  enabledFrom?: Date;
  /**
   *
   * @type {Date}
   * @memberof AnnouncementWrite
   */
  enabledUntil?: Date;
  /**
   *
   * @type {Array<AvailableScopes>}
   * @memberof AnnouncementWrite
   */
  scopes?: Array<AvailableScopes>;
  /**
   *
   * @type {Array<SupportedCountriesEnum>}
   * @memberof AnnouncementWrite
   */
  countries?: Array<SupportedCountriesEnum>;
  /**
   *
   * @type {boolean}
   * @memberof AnnouncementWrite
   */
  isCloseable?: boolean;
}

/**
 * Check if a given object implements the AnnouncementWrite interface.
 */
export function instanceOfAnnouncementWrite(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "internalName" in value;
  isInstance = isInstance && "variant" in value;
  isInstance = isInstance && "content" in value;

  return isInstance;
}

export function AnnouncementWriteFromJSON(json: any): AnnouncementWrite {
  return AnnouncementWriteFromJSONTyped(json, false);
}

export function AnnouncementWriteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnnouncementWrite {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    internalName: json["internal_name"],
    variant: AnnouncementVariantFromJSON(json["variant"]),
    content: json["content"],
    isEnabled: !exists(json, "is_enabled") ? undefined : json["is_enabled"],
    enabledFrom: !exists(json, "enabled_from")
      ? undefined
      : new Date(json["enabled_from"]),
    enabledUntil: !exists(json, "enabled_until")
      ? undefined
      : new Date(json["enabled_until"]),
    scopes: !exists(json, "scopes")
      ? undefined
      : (json["scopes"] as Array<any>).map(AvailableScopesFromJSON),
    countries: !exists(json, "countries")
      ? undefined
      : (json["countries"] as Array<any>).map(SupportedCountriesEnumFromJSON),
    isCloseable: !exists(json, "is_closeable")
      ? undefined
      : json["is_closeable"],
  };
}

export function AnnouncementWriteToJSON(value?: AnnouncementWrite | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    internal_name: value.internalName,
    variant: AnnouncementVariantToJSON(value.variant),
    content: value.content,
    is_enabled: value.isEnabled,
    enabled_from:
      value.enabledFrom === undefined
        ? undefined
        : value.enabledFrom.toISOString().substring(0, 10),
    enabled_until:
      value.enabledUntil === undefined
        ? undefined
        : value.enabledUntil.toISOString().substring(0, 10),
    scopes:
      value.scopes === undefined
        ? undefined
        : (value.scopes as Array<any>).map(AvailableScopesToJSON),
    countries:
      value.countries === undefined
        ? undefined
        : (value.countries as Array<any>).map(SupportedCountriesEnumToJSON),
    is_closeable: value.isCloseable,
  };
}
