import { useState } from "react";

import { useTranslation } from "react-i18next";

import { CurrentUserRead, SupportedLanguagesEnum } from "@vapaus/api-codegen";
import { supportedLanguages } from "@vapaus/i18n";
import { LineItem } from "@vapaus/ui-v2";

import { ChangeLanguageModal } from "../ChangeLanguageModal";

interface LineLanguageProps {
  user?: CurrentUserRead;
}

export function LineLanguage({ user }: LineLanguageProps) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userLanguage = supportedLanguages.find(
    (lang) => lang.id === (user?.language ?? SupportedLanguagesEnum.En),
  );

  return (
    <>
      <LineItem
        name={t("commonV2:language")}
        content={userLanguage?.name}
        action={{
          label: t("commonV2:change"),
          onClick: () => setIsModalOpen(true),
        }}
      />
      <ChangeLanguageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
