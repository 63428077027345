import styled from "styled-components";

import { Flex, Paper, Typography } from "@vapaus/ui-v2";

export type SpecialDealCardProps = {
  id: string;
  title: string;
  description: string;
  backgroundImage: string;
  logoImage: string;
  link: string;
  size?: {
    height: number;
    width: number;
  };
};

export function SpecialDealCard({
  title,
  description,
  backgroundImage,
  logoImage,
  link,
  size,
}: SpecialDealCardProps) {
  return (
    <StyledRoot
      href={link}
      target="_blank"
      rel="noreferrer"
      $width={size?.width}
    >
      <StyledPaper shadow="sm" padding="none">
        <Flex direction="column" gap="none" align="stretch">
          <StyledImageBackground
            fullWidth
            align="center"
            justify="center"
            padding="xl"
            $bgImage={backgroundImage}
            data-testid="background-image"
          />
          <Flex fullWidth padding="md" gap="sm" align="flex-start">
            <StyledLogoWrapper>
              <StyledLogoImage src={logoImage} alt="shop-logo" />
            </StyledLogoWrapper>
            <Flex gap="xs" direction="column">
              <Typography variant="h6">{title}</Typography>
              <Typography>{description}</Typography>
            </Flex>
          </Flex>
        </Flex>
      </StyledPaper>
    </StyledRoot>
  );
}

const StyledRoot = styled.a<{ $width?: number }>`
  display: flex;
  width: ${({ $width }) => $width}px;
`;

const StyledPaper = styled(Paper)`
  overflow: hidden;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.background.main};
`;

const StyledImageBackground = styled(Flex)<{ $bgImage: string }>`
  background: url("${({ $bgImage }) => $bgImage}") no-repeat center center /
    cover;
  width: 100%;
  aspect-ratio: 2;
`;

const StyledLogoWrapper = styled.div`
  border: ${({ theme }) => theme.border.primary.main};
  background: ${({ theme }) => theme.palette.background.white};
  height: ${({ theme }) => theme.icon.fontSizes.lg};
  width: ${({ theme }) => theme.icon.fontSizes.lg};
  min-width: ${({ theme }) => theme.icon.fontSizes.lg};
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
`;

const StyledLogoImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
`;
