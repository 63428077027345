import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AccessoryType } from "@vapaus/api-codegen";
import { useCurrencyFormat, usePercentFormat } from "@vapaus/i18n";
import { useEnumEntries } from "@vapaus/shared-api";
import { Checkbox, Flex, Typography } from "@vapaus/ui-v2";

import { useReturnProcessContext } from "../../../context/ReturnProcessContext";
import { ReturnProcessAccessory } from "../../../types";

type AccessoryProps = {
  accessory: ReturnProcessAccessory;
};

export function Accessory({ accessory }: AccessoryProps) {
  const { t } = useTranslation();
  const {
    currency,
    accessoryIdsSelectedForRedemption,
    setAccessorySelectedForRedemption,
  } = useReturnProcessContext();
  const formatCurrency = useCurrencyFormat(currency);
  const formatPercent = usePercentFormat({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const { getEnumLabel: getAccessoryTypeEnumLabel } =
    useEnumEntries("AccessoryType");

  const isAccessoryDiscounted = accessory.discountPercentage > 0;

  const checked = accessoryIdsSelectedForRedemption.includes(accessory.id);
  const label = `${getAccessoryTypeEnumLabel(accessory.type)}${
    accessory.type !== AccessoryType.Helmet && isAccessoryDiscounted
      ? ` -${formatPercent(accessory.discountPercentage)}`
      : ""
  }`;

  return (
    <Flex justify="space-between">
      <Checkbox
        label={label}
        helpText={`${accessory.manufacturer} ${accessory.model}`}
        checked={checked}
        onChange={() => setAccessorySelectedForRedemption(accessory.id)}
      />
      {accessory.type === AccessoryType.Helmet ? (
        <StyledFreeLabelWrapper>
          <Typography align="right" weight="bold">
            {t("userV2:returnProcess.accessories.free")}
          </Typography>
        </StyledFreeLabelWrapper>
      ) : (
        <Flex direction="column" gap="xxs">
          <Typography align="right" weight="bold">
            {formatCurrency(accessory.redemptionPrice)}
          </Typography>
          {isAccessoryDiscounted ? (
            <Typography decoration="line-through" align="right">
              {formatCurrency(accessory.purchasePrice)}
            </Typography>
          ) : null}
        </Flex>
      )}
    </Flex>
  );
}

const StyledFreeLabelWrapper = styled.div`
  align-self: flex-start;
`;
