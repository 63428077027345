import { ComponentType } from "react";

import styled from "styled-components";

import { SvgIconProps } from "../Icons";

export type CircularIconProps = {
  icon: ComponentType<SvgIconProps>;
};

export const CircularIcon = ({ icon: Icon }: CircularIconProps) => {
  return (
    <StyledRoot>
      <Icon />
    </StyledRoot>
  );
};

const StyledRoot = styled.div`
  border: ${({ theme }) => theme.border.primary.main};
  background: ${({ theme }) => theme.palette.background.white};
  height: ${({ theme }) => theme.icon.fontSizes.lg};
  width: ${({ theme }) => theme.icon.fontSizes.lg};
  min-height: ${({ theme }) => theme.icon.fontSizes.lg};
  min-width: ${({ theme }) => theme.icon.fontSizes.lg};
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`;
