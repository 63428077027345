import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";

import {
  BikeBenefitContractRead,
  ReturnProcessRead,
} from "@vapaus/api-codegen";
import { useToaster } from "@vapaus/ui-v2";

import { useCreateReturnProcess } from "../../../../../../../api/returnProcess";
import { usePayRedemptionOrReturnInvoice } from "../../../../RedeemReturnSharedComponents/hooks/usePayRedemptionOrReturnInvoice";
import { useReturnProcessContext } from "../../../context/ReturnProcessContext";

export function usePayForReturn() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toaster = useToaster();

  const { contract } = useOutletContext<{
    contract: BikeBenefitContractRead;
  }>();

  const {
    onCompleted,
    isContractEndingSoon,
    pickupAddressDetails,
    returnReason,
    preliminaryData,
    accessoryIdsSelectedForRedemption,
    returnMethod,
    images,
  } = useReturnProcessContext();

  const createReturnProcess = useCreateReturnProcess();
  const { onPayInvoice, isLoading: payInvoiceIsLoading } =
    usePayRedemptionOrReturnInvoice();

  const onCreateReturnProcess = (
    onSuccessCallback: (data: ReturnProcessRead) => void,
  ) => {
    createReturnProcess.mutate(
      {
        bikeBenefitContractId: contract.id,
        isEndOfLeaseReturn: isContractEndingSoon,
        leaseEndDate: preliminaryData!.leaseEndDate,
        redeemedAccessoryIds: new Set(accessoryIdsSelectedForRedemption),
        ...(returnMethod === "pickup" && {
          pickupDetails: {
            address: pickupAddressDetails.address!,
            postCode: pickupAddressDetails.postalCode!,
            city: pickupAddressDetails.city!,
            country: pickupAddressDetails.country!,
            phoneNumber: pickupAddressDetails.phoneNumber!,
            requestedPickupDate: pickupAddressDetails.requestedPickupDate!,
          },
        }),
        returnReason: returnReason!,
        pictureIds: images.map((image) => image.id),
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ["contracts"] });
          onSuccessCallback(data);
        },
        onError: () => {
          toaster.add(
            "error",
            t("userV2:returnProcess.paymentSummary.returnErrorMessage"),
          );
        },
      },
    );
  };

  const handlePayLater = () => {
    onCreateReturnProcess(() => {
      onCompleted();
      navigate("..", { replace: true });
    });
  };

  const handlePayNow = () => {
    onCreateReturnProcess((data) => onPayInvoice(data.saleInvoiceId!));
  };

  const onConfirmAndClose = handlePayLater;

  return {
    handlePayLater,
    handlePayNow,
    onConfirmAndClose,
    isLoading: createReturnProcess.isLoading ?? payInvoiceIsLoading,
  };
}
