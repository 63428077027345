/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BillingScheme } from "./BillingScheme";

export type Body_admin_billing_generate_monthly_invoices = {
  erp_name: Body_admin_billing_generate_monthly_invoices.erp_name;
  billing_scheme: BillingScheme;
  invoicing_date: string;
  invoicing_period_year: number;
  invoicing_period_month: number;
};

export namespace Body_admin_billing_generate_monthly_invoices {
  export enum erp_name {
    NET_SUITE = "NetSuite",
    NETVISOR = "Netvisor",
  }
}
