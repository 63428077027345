import { useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import {
  PaymentServiceProvider,
  PaymentStatus,
  SaleInvoiceRead,
} from "@vapaus/api-codegen";
import { useToaster } from "@vapaus/ui-v2";

import { useGetInvoice, usePayInvoice } from "../../../api/invoice";
import {
  useSearchLatestPaymentWithInvoiceId,
  useVismaPayCallback,
} from "../../../api/paymentConsent";
import { VISMA_PAY_RETURN_KEY } from "../../../constants/vismaPay";

type ModalView = "invoice" | "payment" | "payError" | "paymentProcessing";

export function useInvoiceViewModal(
  onClose: () => void,
  onResumePaymentProcess: (invoice: SaleInvoiceRead) => void,
  invoice?: SaleInvoiceRead,
) {
  const { t } = useTranslation();
  const [view, setView] = useState<ModalView>("invoice");

  const toaster = useToaster();
  const payInvoice = usePayInvoice();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();
  const vismaPayInvoiceId = searchParams.get(VISMA_PAY_RETURN_KEY) || undefined;

  const onPayNow = () => setView("payment");

  const onPayContinue = () => {
    invariant(invoice, "invoice must have a value");
    const currentParams = new URLSearchParams(searchParams);
    currentParams.set(VISMA_PAY_RETURN_KEY, invoice.id);
    payInvoice.mutate(
      {
        saleInvoiceId: invoice.id,
        bodyUserInvoicesPaySaleInvoice: {
          paymentServiceProvider: PaymentServiceProvider.VismaPay,
          returnUrl: `${
            window.location.origin + window.location.pathname
          }?${currentParams.toString()}`,
        },
      },
      {
        onSuccess: ({ link }) => {
          queryClient.invalidateQueries(["paymentConsents"]);
          if (!link) return;
          window.location.assign(link);
        },
      },
    );
  };

  const onCancel = () => {
    setView("invoice");
    payInvoice.reset();
    onClose();
  };

  const { data: invoicePayment, isLoading } =
    useSearchLatestPaymentWithInvoiceId(invoice?.id);

  const latestPayment = invoicePayment?.items?.[0];

  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);

  useEffect(() => {
    if (isPaymentInProgress) {
      if (
        (
          [
            PaymentStatus.Failed,
            PaymentStatus.Cancelled,
            PaymentStatus.Unknown,
          ] as PaymentStatus[]
        ).includes(latestPayment?.status as PaymentStatus)
      ) {
        setView("payError");
        setIsPaymentInProgress(false);
      } else if (latestPayment?.status === PaymentStatus.Charged) {
        setView("invoice");
        toaster.add("success", t("userV2:billingTable.paymentSuccessful"));
        setIsPaymentInProgress(false);
      }
    }
  }, [isPaymentInProgress, latestPayment, toaster, t]);

  const currentView: ModalView = isPaymentInProgress
    ? "paymentProcessing"
    : payInvoice.isError
    ? "payError"
    : view;

  const shouldNotShowFooterButtons =
    currentView === "paymentProcessing" ||
    invoice?.status === "paid" ||
    (isLoading && invoice?.id);

  const handleConfirm = shouldNotShowFooterButtons
    ? undefined
    : view === "invoice"
    ? onPayNow
    : onPayContinue;

  const handleCancel = shouldNotShowFooterButtons ? undefined : onCancel;

  const modalConfirmText =
    currentView === "payError"
      ? t("commonV2:tryAgain")
      : view === "payment"
      ? payInvoice.isError
        ? t("commonV2:tryAgain")
        : t("commonV2:continue")
      : t("userV2:billingTable.payNow");

  const { data: vismaPayInvoice } = useGetInvoice(vismaPayInvoiceId);

  const vismaPayCallback = useVismaPayCallback();

  useEffect(() => {
    if (vismaPayInvoiceId) setIsPaymentInProgress(true);
  }, [vismaPayInvoiceId]);

  useEffect(() => {
    if (vismaPayInvoice && searchParams.get("ORDER_NUMBER")) {
      onResumePaymentProcess(vismaPayInvoice);
      vismaPayCallback.mutate(
        {
          oRDERNUMBER: searchParams.get("ORDER_NUMBER") || undefined,
          rETURNCODE: searchParams.get("RETURN_CODE") || undefined,
          sETTLED: searchParams.get("SETTLED") || undefined,
          iNCIDENTID: searchParams.get("INCIDENT_ID") || undefined,
          aUTHCODE: searchParams.get("AUTHCODE") || undefined,
        },
        {
          onSettled: () => {
            queryClient.invalidateQueries(["invoices"]);
            queryClient.invalidateQueries(["invoicePayments"]);
            queryClient.invalidateQueries(["orders"]);
          },
        },
      );
      setSearchParams();
    }
  }, [
    onResumePaymentProcess,
    setSearchParams,
    queryClient,
    searchParams,
    vismaPayCallback,
    vismaPayInvoice,
  ]);

  return {
    handleCancel,
    handleConfirm,
    modalConfirmText,
    currentView,
    isLoading: payInvoice.isLoading,
  };
}
