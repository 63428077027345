/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
} from "./SupportedCountriesEnum";

/**
 *
 * @export
 * @interface ReturnProcessPickupDetailsWrite
 */
export interface ReturnProcessPickupDetailsWrite {
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  requestedPickupDate: Date;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  country: SupportedCountriesEnum;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  postCode: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  phoneNumber: string;
}

/**
 * Check if a given object implements the ReturnProcessPickupDetailsWrite interface.
 */
export function instanceOfReturnProcessPickupDetailsWrite(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "requestedPickupDate" in value;
  isInstance = isInstance && "country" in value;
  isInstance = isInstance && "address" in value;
  isInstance = isInstance && "postCode" in value;
  isInstance = isInstance && "city" in value;
  isInstance = isInstance && "phoneNumber" in value;

  return isInstance;
}

export function ReturnProcessPickupDetailsWriteFromJSON(
  json: any,
): ReturnProcessPickupDetailsWrite {
  return ReturnProcessPickupDetailsWriteFromJSONTyped(json, false);
}

export function ReturnProcessPickupDetailsWriteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessPickupDetailsWrite {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    requestedPickupDate: new Date(json["requested_pickup_date"]),
    country: SupportedCountriesEnumFromJSON(json["country"]),
    address: json["address"],
    postCode: json["post_code"],
    city: json["city"],
    phoneNumber: json["phone_number"],
  };
}

export function ReturnProcessPickupDetailsWriteToJSON(
  value?: ReturnProcessPickupDetailsWrite | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    requested_pickup_date: value.requestedPickupDate
      .toISOString()
      .substring(0, 10),
    country: SupportedCountriesEnumToJSON(value.country),
    address: value.address,
    post_code: value.postCode,
    city: value.city,
    phone_number: value.phoneNumber,
  };
}
