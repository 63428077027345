import { useState } from "react";

import { ColumnDef, Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { ShopType, UserShopRead } from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import {
  Card,
  Flex,
  Lens3DIcon,
  Table,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { useShopsTable } from "../useShopsTable";
import { ShopBrands } from "./ShopBrands";
import { ShopDetailsDrawer } from "./ShopDetailsDrawer/ShopDetailsDrawer";
import { ShopName } from "./ShopName";
import { ShopServices } from "./ShopServices";
import { ShopTableFilters } from "./ShopTableFilters";

export function ShopsTableCard() {
  const { t } = useTranslation();

  const {
    data,
    paginationOptions,
    sortingOptions,
    searchQuery,
    filteredCity,
    filteredBrandId,
    filteredShopType,
    areFiltersEmpty,
    setSearchQuery,
    setFilteredCity,
    setFilteredBrand,
    setFilteredShopType,
    clearFilters,
  } = useShopsTable();

  const { isSm, isXs } = useBreakpoints();

  const [openedShop, setOpenedShop] = useState<UserShopRead>();

  const columns: ColumnDef<UserShopRead>[] = [
    {
      header: t("userV2:getABike.partnerShops.table.shop"),
      cell: ({ row }) => <ShopName shop={row.original} />,
      accessorKey: "name",
      enableSorting: true,
      size: 40,
    },
    ...(isXs
      ? []
      : [
          {
            header: t("userV2:getABike.partnerShops.table.brands"),
            cell: ({ row }: { row: Row<UserShopRead> }) => (
              <ShopBrands brands={row.original.bikeBrands} />
            ),
            size: 30,
            accessorKey: "brands",
          },
          ...(isSm
            ? []
            : [
                {
                  header: t("userV2:getABike.partnerShops.table.services"),
                  cell: ({ row }: { row: Row<UserShopRead> }) => (
                    <ShopServices services={row.original.types} />
                  ),
                  accessorKey: "services",
                },
              ]),
          {
            header: t("userV2:getABike.partnerShops.table.location"),
            cell: ({ row }: { row: Row<UserShopRead> }) => (
              <Typography align="right">
                {row.original.types.length === 1 &&
                row.original.types.includes(ShopType.OnlineStore)
                  ? "-"
                  : row.original.city}
              </Typography>
            ),
            accessorKey: "city",
            enableSorting: true,
          },
        ]),
  ];

  return (
    <Card>
      <Flex direction="column" gap="lg">
        <ShopTableFilters
          totalDisplay={paginationOptions?.totalItems}
          searchQuery={searchQuery}
          filteredCity={filteredCity}
          filteredBrandId={filteredBrandId}
          areFiltersEmpty={areFiltersEmpty}
          filteredShopType={filteredShopType}
          setSearchQuery={setSearchQuery}
          setFilteredCity={setFilteredCity}
          setFilteredBrand={setFilteredBrand}
          setFilteredShopType={setFilteredShopType}
          clearFilters={clearFilters}
        />
        <Table<UserShopRead>
          data={data}
          columns={data?.length ? columns : []}
          paginationOptions={paginationOptions}
          sortingOptions={sortingOptions}
          emptyStateComponent={
            <TableEmptyState
              icon={<Lens3DIcon size="md" />}
              title={t("userV2:getABike.partnerShops.emptyTable.title")}
              subtitle={t("userV2:getABike.partnerShops.emptyTable.subtitle")}
            />
          }
          onRowClick={(shop) => setOpenedShop(shop)}
        />
      </Flex>
      <ShopDetailsDrawer
        shop={openedShop}
        onClose={() => setOpenedShop(undefined)}
      />
    </Card>
  );
}
