import { useId } from "react";

import styled from "styled-components";

import { Currency } from "@vapaus/generated";
import { useCurrencyFormat } from "@vapaus/i18n";
import { Slider, Tooltip, Typography } from "@vapaus/ui-v2";

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface SliderInputProps {
  label: string;
  helpText: string;
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
  name: string;
}

export function SliderInput({
  label,
  helpText,
  onChange,
  min,
  max,
  step,
  value,
  name,
  ...props
}: SliderInputProps) {
  const formatCurrency = useCurrencyFormat(Currency.EUR, {
    maximumFractionDigits: 0,
  });
  const inputId = useId();

  return (
    <div>
      <Head>
        <Typography as="label" weight="bold" htmlFor={inputId}>
          {label}
        </Typography>
        <Tooltip>{helpText}</Tooltip>
      </Head>
      <Slider
        onChange={(e: Event, value: number) => onChange(value)}
        min={min}
        max={max}
        step={step}
        value={value}
        name={name}
        slotProps={{ input: { id: inputId } }}
        {...props}
      />
      <Typography weight="bold">{formatCurrency(value)}</Typography>
    </div>
  );
}
