import { useState } from "react";

import { useTranslation } from "react-i18next";

import { CurrentUserRead } from "@vapaus/api-codegen";
import { useDisplayName } from "@vapaus/i18n";
import { Flex, LineItem, Typography } from "@vapaus/ui-v2";

import { ChangeAddressModal } from "../ChangeAddressModal";

interface LineAddressProps {
  user?: CurrentUserRead;
}

export function LineAddress({ user }: LineAddressProps) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const displayNameOf = useDisplayName();

  return (
    <>
      <LineItem
        name={t("commonV2:address")}
        content={
          <>
            <Typography>{user?.address}</Typography>
            <Flex gap="xs">
              <Typography>{user?.postCode}</Typography>
              <Typography>{user?.city}</Typography>
            </Flex>
            <Typography>{displayNameOf(user?.country)}</Typography>
          </>
        }
        action={{
          label: t("commonV2:edit"),
          onClick: () => setIsModalOpen(true),
        }}
      />
      <ChangeAddressModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
