import { Trans, useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { BikeBenefitContractRead, Currency } from "@vapaus/api-codegen";
import { useCurrencyFormat, useDateFormat } from "@vapaus/i18n";
import { Flex, HorizontalLine, Paper, RowItem } from "@vapaus/ui-v2";

import { NameValueDescription } from "../../../../../../../components/NameValueDescription";
import { getIsContractEndingSoon } from "../../../../../../../utils/getIsContractEndingSoon";
import { PayLaterButton } from "../../../../RedeemReturnSharedComponents/PayLaterButton/PayLaterButton";
import { PayNowButton } from "../../../../RedeemReturnSharedComponents/PayNowButton/PayNowButton";
import { WizardPageViewLayout } from "../../../../WizardPageViewLayout";
import { useRedemptionProcessContext } from "../../../context/RedemptionProcessContext";
import { usePayForRedemptionProcess } from "../../../hooks/usePayForRedemptionProcess";

export function PriceConfirmationView() {
  const { t } = useTranslation();
  const { calculation } = useRedemptionProcessContext();
  const { isLoading, handlePayNow, handlePayLater } =
    usePayForRedemptionProcess();
  const { contract } = useOutletContext<{
    contract: BikeBenefitContractRead;
  }>();
  const isEndingSoon = getIsContractEndingSoon(contract.endDate);
  const formatCurrency = useCurrencyFormat(contract.currency || Currency.Eur);
  const formatDate = useDateFormat();
  const discountPercent = (
    0 -
    (contract.bikePrice > 0
      ? ((calculation?.discount ?? 0) / contract.bikePrice) * 100
      : 0)
  ).toFixed(2);

  const calculateOriginalPrice = () => {
    const originalPrice = calculation
      ? calculation.redemptionPrice -
        calculation.redemptionFee -
        calculation.overspentMaintenanceBudget +
        calculation.discount +
        calculation.leasingFeesPaid
      : 0;
    return originalPrice;
  };

  const calculateBikeResidualValue = () => {
    const bikeResidualValue = calculation
      ? calculation.redemptionPrice -
        calculation.overspentMaintenanceBudget +
        calculation.discount
      : 0;
    return bikeResidualValue;
  };

  return (
    <WizardPageViewLayout
      title={t("userV2:redemptionProcess.priceConfirmation.title")}
      description={
        <Trans
          t={t}
          i18nKey="userV2:redemptionProcess.priceConfirmation.description"
          values={{ date: formatDate(calculation?.plannedRedemptionDate) }}
          components={{ 1: <b /> }}
        />
      }
    >
      <Paper shadow="md">
        <Flex direction="column" gap="md" fullWidth>
          {isEndingSoon ? (
            <NameValueDescription
              value={formatCurrency(calculateBikeResidualValue())}
              name={t(
                "userV2:redemptionProcess.priceConfirmation.bikeResidualValue.name",
              )}
              description={t(
                "userV2:redemptionProcess.priceConfirmation.bikeResidualValue.description",
              )}
            />
          ) : (
            <NameValueDescription
              value={formatCurrency(calculateOriginalPrice())}
              name={t(
                "userV2:redemptionProcess.priceConfirmation.originalCost.name",
              )}
              description={t(
                "userV2:redemptionProcess.priceConfirmation.originalCost.description",
              )}
            />
          )}
          {calculation?.leasingFeesPaid && !isEndingSoon ? (
            <NameValueDescription
              value={formatCurrency(0 - calculation.leasingFeesPaid)}
              name={t(
                "userV2:redemptionProcess.priceConfirmation.paymentMade.name",
              )}
              description={t(
                "userV2:redemptionProcess.priceConfirmation.paymentMade.description",
              )}
            />
          ) : null}
          {calculation?.discount ? (
            <NameValueDescription
              value={formatCurrency(0 - calculation.discount)}
              name={t(
                "userV2:redemptionProcess.priceConfirmation.discount.name",
                { value: discountPercent },
              )}
              description={t(
                "userV2:redemptionProcess.priceConfirmation.discount.description",
              )}
            />
          ) : null}

          {calculation?.redemptionFee && !isEndingSoon ? (
            <NameValueDescription
              value={formatCurrency(calculation.redemptionFee)}
              name={t(
                "userV2:redemptionProcess.priceConfirmation.redemptionFee.name",
              )}
              description={t(
                "userV2:redemptionProcess.priceConfirmation.redemptionFee.description",
              )}
            />
          ) : null}
          {calculation?.overspentMaintenanceBudget ? (
            <NameValueDescription
              value={formatCurrency(calculation?.overspentMaintenanceBudget)}
              name={t(
                "userV2:redemptionProcess.priceConfirmation.overspentBudget.name",
              )}
              description={t(
                "userV2:redemptionProcess.priceConfirmation.overspentBudget.description",
              )}
            />
          ) : null}
          <HorizontalLine marginBottom="none" marginTop="none" />
          <RowItem
            label={t("userV2:redemptionProcess.priceConfirmation.total")}
            value={formatCurrency(calculation?.redemptionPrice)}
            valueIsBold
          />
          <PayNowButton
            price={calculation?.redemptionPrice}
            handlePayNow={handlePayNow}
            isLoading={isLoading}
          />
          <Flex fullWidth justify="center">
            <PayLaterButton
              handlePayLater={handlePayLater}
              isLoading={isLoading}
            />
          </Flex>
        </Flex>
      </Paper>
    </WizardPageViewLayout>
  );
}
