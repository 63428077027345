import { useDateFormat } from "@vapaus/i18n";

type InvoiceDueDateProps = {
  dueDate: Date;
};

export function InvoiceDueDate({ dueDate }: InvoiceDueDateProps) {
  const formatDate = useDateFormat();
  return <article>{formatDate(dueDate)}</article>;
}
