import { Trans, useTranslation } from "react-i18next";

import { Currency } from "@vapaus/api-codegen";
import { useCurrencyFormat, usePercentFormat } from "@vapaus/i18n";

import { BenefitCalculationWithExtra } from "../types";
import { DescribedValue } from "./DescribedValue";
import { InfoBox } from "./InfoBox";

interface LeasingContractProps {
  calculation?: BenefitCalculationWithExtra;
}

export function LeasingContract({ calculation }: LeasingContractProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(Currency.Eur);
  const formatPercent = usePercentFormat({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return (
    <InfoBox
      background="secondary2.lighter"
      title={t("calculator:leasingContract.title")}
      value={formatCurrency(calculation?.totalValueAmount)}
      description={
        <Trans
          i18nKey="calculator:leasingContract.description"
          values={{ leasingPeriodMonths: calculation?.leasingPeriodMonths }}
          defaults="Your bike’s leasing contract length is <b>{{ leasingPeriodMonths }} months</b>. Your employer will automatically deduct 100 €/month from your salary to cover for the cost of the bike."
          components={{ b: <b /> }}
        />
      }
      summaryTitle={t("calculator:leasingContract.total.title")}
      summaryValue={formatCurrency(calculation?.totalValueAmount)}
    >
      <DescribedValue
        title={t("calculator:leasingContract.cost.title")}
        value={formatCurrency(calculation?.totalPackagePrice)}
        description={t("calculator:leasingContract.cost.description", {
          vatRate: formatPercent(calculation?.vatRate),
        })}
      />
      <DescribedValue
        title={t("calculator:leasingContract.insurance.title")}
        value={formatCurrency(calculation?.totalInsuranceCost)}
        description={t("calculator:leasingContract.insurance.description")}
      />
      <DescribedValue
        title={t("calculator:leasingContract.financing.title")}
        value={formatCurrency(calculation?.totalFinancingCost)}
        description={t("calculator:leasingContract.financing.description")}
      />
      <DescribedValue
        title={t("calculator:leasingContract.serviceFee.title")}
        value={formatCurrency(calculation?.totalServiceCost)}
        description={
          <Trans
            i18nKey="calculator:leasingContract.serviceFee.description"
            components={{
              1: (
                <a
                  href={t("calculator:leasingContract.serviceFee.link") ?? ""}
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              ),
            }}
          />
        }
      />
    </InfoBox>
  );
}
