import { marked } from "marked";
import styled from "styled-components";

import { makeTransition } from "../styles";

export type MarkdownProps = {
  content: string;
  className?: string;
};

function cleanUrl(href: string) {
  try {
    href = encodeURI(href).replace(/%25/g, "%");
  } catch {
    return null;
  }
  return href;
}

marked.use({
  extensions: [
    {
      name: "link",
      renderer({ href, title, tokens }): string {
        if (!tokens) return "";
        const text = this.parser.parseInline(tokens);
        const cleanHref = cleanUrl(href);
        if (cleanHref === null) {
          return text;
        }
        href = cleanHref;
        let out = '<a href="' + href + '"';
        if (title) {
          out += ' title="' + title + '"';
        }
        out += 'target="_blank" rel="noreferrer">' + text + "</a>";
        return out;
      },
    },
  ],
});

export function Markdown({ content, className }: MarkdownProps) {
  return (
    <StyledRoot className={className}>
      <div
        dangerouslySetInnerHTML={{
          __html: marked(content),
        }}
      />
    </StyledRoot>
  );
}

export const StyledRoot = styled.div`
  width: 100%;
  word-wrap: break-word;
  font-size: ${({ theme }) => theme.typography.htmlFontSize};
  color: ${({ theme }) => theme.typography.defaultColor};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  h1 {
    margin: 0;
    font-family: ${({ theme }) => theme.typography.h1.fontFamily};
    font-size: ${({ theme }) => theme.typography.h1.fontSize};
    font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
    line-height: ${({ theme }) => theme.typography.h1.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h1.textTransform};
  }
  h2 {
    font-family: ${({ theme }) => theme.typography.h2.fontFamily};
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
    line-height: ${({ theme }) => theme.typography.h2.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h2.textTransform};
  }
  h3 {
    font-family: ${({ theme }) => theme.typography.h3.fontFamily};
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
    font-weight: ${({ theme }) => theme.typography.h3.fontWeight};
    line-height: ${({ theme }) => theme.typography.h3.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h3.textTransform};
  }
  h4 {
    font-family: ${({ theme }) => theme.typography.h4.fontFamily};
    font-size: ${({ theme }) => theme.typography.h4.fontSize};
    font-weight: ${({ theme }) => theme.typography.h4.fontWeight};
    line-height: ${({ theme }) => theme.typography.h4.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h4.textTransform};
  }
  h5 {
    font-family: ${({ theme }) => theme.typography.h5.fontFamily};
    font-size: ${({ theme }) => theme.typography.h5.fontSize};
    font-weight: ${({ theme }) => theme.typography.h5.fontWeight};
    line-height: ${({ theme }) => theme.typography.h5.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h5.textTransform};
  }
  h6 {
    font-family: ${({ theme }) => theme.typography.h6.fontFamily};
    font-size: ${({ theme }) => theme.typography.h6.fontSize};
    font-weight: ${({ theme }) => theme.typography.h6.fontWeight};
    line-height: ${({ theme }) => theme.typography.h6.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h6.textTransform};
  }
  p {
    margin: 0;
    font-family: ${({ theme }) => theme.typography.bodyMd.fontFamily};
    font-size: ${({ theme }) => theme.typography.bodyMd.fontSize};
    font-weight: ${({ theme }) => theme.typography.bodyMd.fontWeight};
    line-height: ${({ theme }) => theme.typography.bodyMd.lineHeight};
  }
  a {
    text-decoration: ${({ theme }) => theme.typography.link.textDecoration};
    color: ${({ theme }) => theme.typography.defaultColor};
    font-weight: ${({ theme }) => theme.typography.link.fontWeight};
    font-family: ${({ theme }) => theme.typography.link.fontFamily};
    cursor: pointer;
    &:hover {
      text-decoration: ${({ theme }) => theme.typography.link.textDecoration};
    }
    &:active {
      color: ${({ theme }) => theme.palette.primary1.light};
    }
    &.disabled {
      pointer-events: none;
      opacity: ${({ theme }) => theme.opacity.disabled};
    }
    ${makeTransition("all", "standard", "easeOut")}
  }
`;
