import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { PaginatedBikeBrandOut } from "@vapaus/api-codegen";
import { AutocompleteSelectAsync } from "@vapaus/common-v2";
import { AutocompleteSelectOptionType, Typography } from "@vapaus/ui-v2";

import { bikeBrandApi } from "../../../../../../../api/bikeBrand";

type BrandSelectProps = {
  brandId?: string;
  onChange: (value: string) => void;
};

export function BrandSelect({ onChange, brandId }: BrandSelectProps) {
  const { t } = useTranslation();
  const [selectedBrand, setSelectedBrand] =
    useState<AutocompleteSelectOptionType | null>(null);

  const brandsQueryFunction = (params: { q: string; pageIndex?: number }) =>
    bikeBrandApi.userShopBikeBrandsSearchBikeBrands({
      active: true,
      q: params?.q,
      sortField: "name",
      sortDirection: "asc",
      limit: 20,
      skip: (params?.pageIndex ?? 0) * 20,
      hasShops: true,
    });

  const selectBrandSuggestions = (data: PaginatedBikeBrandOut) =>
    (data?.items || []).map((brand) => ({
      value: brand.id as string,
      label: brand.name,
    }));

  const handleBrandSelection = (value: AutocompleteSelectOptionType | null) => {
    onChange(value?.value as string);
    setSelectedBrand(value);
  };

  useEffect(() => {
    if (!brandId) setSelectedBrand(null);
  }, [brandId]);

  return (
    <AutocompleteSelectAsync
      queryKey="brandSelect"
      queryFn={brandsQueryFunction}
      querySelect={selectBrandSuggestions}
      noResultMessage={t("commonV2:autocomplete.noResultMessage")}
      onChange={(_, value) => handleBrandSelection(value)}
      inputPlaceholder={t("commonV2:search")}
      placeholder={
        <Typography weight="bold" noWrap as="span">
          {t("userV2:getABike.partnerShops.allBrands")}
        </Typography>
      }
      value={selectedBrand}
      autoPopperWidth
      fullWidth
    />
  );
}
