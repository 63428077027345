import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled, { css } from "styled-components";

import { Announcements, ProfileDropdown } from "@vapaus/common-v2";
import { useCurrentUser } from "@vapaus/shared-api";
import {
  BikeScooterIcon,
  LogoutIcon,
  MobileNavigationBar,
  NavigationBar,
  PersonIcon,
  SettingsIcon,
  SupportIcon,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { Path } from "../../constants/paths";
import { BenefitTermsChecker } from "../BenefitTermsChecker";
import { Footer } from "./components/Footer";
import { MobileNavigationButtons } from "./components/MobileNavigationButtons";
import { NavigationButtons } from "./components/NavigationButtons";

export function Navigation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: user } = useCurrentUser();
  const { isMobile } = useBreakpoints();

  const hideNavigation =
    isMobile && new RegExp(`^\\/${Path.myBikes}\\/.+`).test(location.pathname);

  const menuProps = useMemo(
    () => ({
      header: {
        icon: PersonIcon,
        title: user?.fullName ?? "",
        description: user?.email ?? "",
      },
      items: [
        {
          label: t("commonV2:account"),
          value: Path.profile,
          icon: SettingsIcon,
        },
        {
          label: t("commonV2:support"),
          value: Path.support,
          icon: SupportIcon,
        },
        {
          label: t("userV2:navigation.buttons.sharedService"),
          value: "sharedService",
          icon: BikeScooterIcon,
          onItemClick: () => {
            window.open(import.meta.env.VITE_FLEET_APP_URL, "_blank");
          },
        },
        {
          label: t("commonV2:logout"),
          value: Path.logout,
          icon: LogoutIcon,
        },
      ],
    }),
    [t, user?.email, user?.fullName],
  );

  return (
    <>
      {user && user.country !== undefined && (
        <Announcements
          country={user.country}
          scope="user"
          language={user.language || "EN"}
        />
      )}
      <ScrollRestoration />
      <BenefitTermsChecker />
      {hideNavigation ? (
        <>
          <Outlet />
          <Footer />
        </>
      ) : (
        <>
          <NavigationBar
            onLogoClick={() => navigate("")}
            mainContent={<NavigationButtons />}
            profileMenu={<ProfileDropdown {...menuProps} />}
          />
          <StyledContainer>
            <Outlet />
            <Footer />
          </StyledContainer>
          {isMobile ? (
            <MobileNavigationBar>
              <MobileNavigationButtons />
            </MobileNavigationBar>
          ) : null}
        </>
      )}
    </>
  );
}

const StyledContainer = styled.div`
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding-bottom: ${theme.height.navBar};
    `)}
`;
