import { useTranslation } from "react-i18next";

import { useDateFormat } from "@vapaus/i18n";
import {
  CircularIcon,
  Flex,
  ReceiptIcon,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

type InvoiceNumberProps = {
  invoiceNumber: string;
  createdAt?: Date;
};

export function InvoiceNumber({
  invoiceNumber,
  createdAt,
}: InvoiceNumberProps) {
  const { t } = useTranslation();
  const formatDate = useDateFormat();
  const { isXs } = useBreakpoints();

  return (
    <Flex>
      {!isXs ? <CircularIcon icon={ReceiptIcon} /> : null}
      <Flex direction="column" gap="xss">
        <Typography weight="bold">
          {t("userV2:billingTable.invoiceNumber", { invoiceNumber })}
        </Typography>
        <Typography>{formatDate(createdAt)}</Typography>
      </Flex>
    </Flex>
  );
}
