import { Currency } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";

type InvoiceAmountProps = {
  amount: number;
  currency: Currency;
};

export function InvoiceAmount({ amount, currency }: InvoiceAmountProps) {
  const formatCurrency = useCurrencyFormat(currency);
  return <article>{formatCurrency(amount)}</article>;
}
