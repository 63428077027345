import styled, { css } from "styled-components";

import { UserShopRead } from "@vapaus/api-codegen";
import { Flex, HorizontalLine } from "@vapaus/ui-v2";

import { BrandsSection } from "./components/BrandsSection";
import { ContactInfoSection } from "./components/ContactInfoSection";
import { ServicesSection } from "./components/ServicesSection";

type ShopDetailsBodySectionProps = {
  shop: UserShopRead;
};

export function ShopDetailsBodySection({ shop }: ShopDetailsBodySectionProps) {
  return (
    <StyledRoot direction="column" gap="none">
      <ContactInfoSection shop={shop} />
      {shop.bikeBrands && shop.bikeBrands.length > 0 ? (
        <>
          <HorizontalLine marginBottom="md" marginTop="md" />
          <BrandsSection shop={shop} />
        </>
      ) : null}
      <HorizontalLine marginBottom="md" marginTop="md" />
      <ServicesSection shop={shop} />
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding: ${({ theme }) => theme.spacing.xl}
        ${({ theme }) => theme.spacing.md};
    `)}
`;
