import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { UserShopRead } from "@vapaus/api-codegen";
import { Button, Flex, useBreakpoints } from "@vapaus/ui-v2";

import {
  canyonShopId,
  commencalShopId,
  econicOneShopId,
  verkkokauppaShopId,
} from "../../../hardCodedShopIds";
import { getShopDetails } from "../../../utils/getShopDetails";

type ButtonsSectionProps = {
  shop: UserShopRead;
};

export function ButtonsSection({ shop }: ButtonsSectionProps) {
  const { t } = useTranslation();
  const shopDetails = getShopDetails(shop, t);
  const { isMobile } = useBreakpoints();

  return (
    <Flex smDirection="column" fullWidth={isMobile}>
      {shop.website ? (
        <Button size="small" fullWidth={isMobile}>
          <Link to={shop.website} target="_blank" rel="noreferrer">
            {t("userV2:getABike.partnerShops.visitWebsite")}
          </Link>
        </Button>
      ) : null}
      {[
        econicOneShopId,
        commencalShopId,
        verkkokauppaShopId,
        canyonShopId,
      ].includes(shop.id) ? (
        <Button size="small" variant="outlined" fullWidth={isMobile}>
          <Link
            to={shopDetails.formLink ?? ""}
            target="_blank"
            rel="noreferrer"
          >
            {t("userV2:getABike.partnerShops.orderForm")}
          </Link>
        </Button>
      ) : null}
    </Flex>
  );
}
