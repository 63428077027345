import styled, { AnyStyledComponent, css } from "styled-components";

import { Flex, StorefrontIcon } from "@vapaus/ui-v2";

type ShopLogoProps = {
  logoUrl?: string;
};

export function ShopLogo({ logoUrl }: ShopLogoProps) {
  return (
    <StyledIconWrapper align="center" justify="center">
      {logoUrl ? (
        <StyledLogoImage src={logoUrl} alt="Logo" />
      ) : (
        <StyledStorefrontIcon />
      )}
    </StyledIconWrapper>
  );
}

const StyledIconWrapper = styled(Flex)`
  width: 128px;
  height: 128px;
  background-color: ${({ theme }) => theme.palette.background.white};
  border-radius: 100%;
  overflow: hidden;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      width: 96px;
      height: 96px;
    `)}
`;

const StyledLogoImage = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledStorefrontIcon = styled(
  StorefrontIcon as unknown as AnyStyledComponent,
)`
  font-size: 5rem;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      font-size: 3.5rem;
    `)}
`;
