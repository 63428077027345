import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { useScrollToAnchor } from "@vapaus/common-v2";

import { useGetOrder } from "../../../api/benefit";
import { BillingTable } from "../../../components/BillingTable";

export function TabPanelBilling() {
  const { orderId } = useParams();
  invariant(orderId);
  const { t } = useTranslation();
  const { data: order } = useGetOrder(orderId);
  const billingDivRef = useScrollToAnchor("focus");

  if (!order) return null;

  return (
    <div ref={billingDivRef}>
      <BillingTable
        title={t("userV2:myBike.billing.title")}
        bikeBenefitOrderId={order.id}
        bikeBenefitContractId={order.currentBikeBenefitContractId}
      />
    </div>
  );
}
