import { useQuery } from "@tanstack/react-query";

import {
  AnnouncementRead,
  AnnouncementsApi,
  AnnouncementsGetAnnouncementsRequest,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const announcementApi = new AnnouncementsApi(apiConfiguration);

export const useGetAnnouncements = (
  params: AnnouncementsGetAnnouncementsRequest,
  options?: UseQueryOptions<Array<AnnouncementRead>>,
) => {
  return useQuery<Array<AnnouncementRead>, ResponseError>(
    ["announcements", params],
    () => announcementApi.announcementsGetAnnouncements(params),
    options,
  );
};
