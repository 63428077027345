import { useTranslation } from "react-i18next";

import { useDisplayName } from "@vapaus/i18n";
import { useCurrentUser } from "@vapaus/shared-api";
import { Card, HorizontalLine, LineItem, Typography } from "@vapaus/ui-v2";

import { LineAddress } from "../LineAddress";
import { LineName } from "../LineName";

export function UserProfileCard() {
  const { data: user } = useCurrentUser();
  const { t } = useTranslation();
  const displayNameOf = useDisplayName();

  return (
    <Card title={t("commonV2:userProfile.title")}>
      <LineName user={user} />
      <HorizontalLine />
      <LineAddress user={user} />
      <HorizontalLine />
      <LineItem
        name={t("commonV2:country")}
        content={<Typography>{displayNameOf(user?.country)}</Typography>}
      />
    </Card>
  );
}
