import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Plan } from "@vapaus/api-codegen";

import { CalculatorCustomOrLegacyBenefitPage } from "./CalculatorCustomOrLegacyBenefitPage";
import { CalculatorDefaultPage } from "./CalculatorDefaultPage";
import { CalculatorNoBenefitPage } from "./CalculatorNoBenefitPage";
import { CalculatorSwedenPage } from "./CalculatorSwedenPage";
import { useUserBenefitDefinition } from "./useUserBenefitDefinition";

export function CalculatorPage() {
  const navigate = useNavigate();
  const { benefitDefinition, isLoading } = useUserBenefitDefinition();

  useEffect(() => {
    if (isLoading) {
      return;
    }
  }, [benefitDefinition?.plan, isLoading, navigate]);

  if (isLoading) {
    return <StyledEmptyRoot />;
  }

  if (!benefitDefinition) {
    return <CalculatorNoBenefitPage />;
  }

  if (
    benefitDefinition.plan === Plan.Custom ||
    benefitDefinition.plan === Plan.Legacy
  ) {
    return <CalculatorCustomOrLegacyBenefitPage />;
  }

  if (benefitDefinition.plan === Plan.SwedenPilot) {
    return <CalculatorSwedenPage />;
  }

  return <CalculatorDefaultPage benefitDefinition={benefitDefinition} />;
}

const StyledEmptyRoot = styled.div`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  min-height: calc(100vh - ${({ theme }) => theme.height.navBar});
`;
